import { Divider, Grid, Switch, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

import { TextInputField, SelectInputField, CurrencySelect } from "components/FormField";
import SoftBox from "components/SoftBox";
import { countriesCode } from "constants/countriesCode";
import SoftTypography from "components/SoftTypography";
import SortedCountrySelect from "../../../components/SortedCountrySelect";
import React, { useMemo } from "react";
import { useGetOrganizations } from "pages/organizations/hooks";
import { generateOptions } from "utils/options";
import Tooltip from "@mui/material/Tooltip";

export const sanitizeRequest = ({
  id,
  name,
  legalName,
  registrationNumber,
  website,
  companyPhone,
  companyEmail,
  companyAddress,
  country,
  currency,
  mccCode,
  vat,
  cardBaseCurrency,
  code,
  enableCashier,
  enableDirect,
  enableHybrid,
  excludeFromReports,
  organizationId,
  highrisk,
}) => {
  const filteredPayload = _.pickBy(
    {
      id,
      name,
      legalName,
      registrationNumber,
      website,
      companyPhone,
      companyEmail,
      companyAddress,
      country,
      currency,
      mccCode,
      vat,
      code: code,
      cardBaseCurrency,
      organizationId,
    },
    _.identity
  );
  return {
    ...filteredPayload,
    enableCashier,
    enableDirect,
    enableHybrid,
    excludeFromReports,
    highrisk,
  };
};
const MerchantForm = () => {
  // hooks
  const { data: orgs } = useGetOrganizations();
  const orgsOptions = useMemo(() => {
    return generateOptions(orgs, false);
  }, [orgs]);

  const { setValue, watch } = useFormContext();
  const enableCashier = watch("enableCashier");
  const enableDirect = watch("enableDirect");
  const enableHybrid = watch("enableHybrid");
  const excludeFromReports = watch("excludeFromReports");
  const highrisk = watch("highrisk");

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Name" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Legal name" name="legalName" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SortedCountrySelect
            name="country"
            label="Operating Country"
            setValue={setValue}
            sx={{ zIndex: 1000 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencySelect name="currency" label="Main Currency" placeholder="Select currency" />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid container display="flex" pl={3} xs={6} pt={3}>
          <Grid item xs={4}>
            <SelectInputField
              name="code"
              label="Code"
              options={_.map(countriesCode, (option) => ({
                label: `${option.label} +${option.phone}`,
                value: `+${option.phone}`,
              }))}
              placeholder="Select an option"
            />
          </Grid>
          <Grid item xs={8} pl={2}>
            <TextInputField label="Company Phone" name="companyPhone" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Company Email" name="companyEmail" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Company Address" name="companyAddress" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Merchant Category Code" name="mccCode" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="VAT" name="vat" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencySelect
            name="cardBaseCurrency"
            label="Card Base Currency"
            placeholder="Select currency"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Registration Number" name="registrationNumber" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Website URL" name="website" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectInputField label="Organization" name="organizationId" options={orgsOptions} />
        </Grid>
      </Grid>
      <Divider />
      <Grid display="flex" justifyContent={"space-between"} gap={3} flexWrap={"wrap"}>
        <SoftBox display="flex" mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={enableCashier}
              onChange={(_event, checked) => setValue("enableCashier", checked)}
            />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Enable Cashier
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={enableDirect}
              onChange={(_event, checked) => setValue("enableDirect", checked)}
            />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Enable Direct
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={enableHybrid}
              onChange={(_event, checked) => setValue("enableHybrid", checked)}
            />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Enable Hybrid
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={excludeFromReports}
              onChange={(_event, checked) => setValue("excludeFromReports", checked)}
            />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Exclude From Reports
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <Tooltip title="High Risk merchants will use the cyberpay.link domain.">
          <SoftBox display="flex" mb={0.25}>
            <SoftBox mt={0.25}>
              <Switch
                checked={highrisk}
                onChange={(_event, checked) => setValue("highrisk", checked)}
              />
            </SoftBox>
            <SoftBox width="80%" ml={2}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                High Risk
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Tooltip>
      </Grid>
    </Box>
  );
};

export default MerchantForm;
