/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "examples/LayoutContainers/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";
import { useForm } from "react-hook-form";
import { useSignIn } from "./useAuth";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toastError } from "../../utils/toast";

function SignIn() {
  const { register, handleSubmit } = useForm({ defaultValues: { email: "", password: "" } });
  const navigate = useNavigate();
  const { mutate, isLoading } = useSignIn();

  const handleSubmitForm = (values) => {
    mutate(values, {
      onError: () => {
        toastError({
          description: `Invalid email or password!`,
        });
      },
    });
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: chat,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit(handleSubmitForm)}>
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" {...register("email")} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput
            type="password"
            placeholder="Password"
            size="large"
            {...register("password")}
          />
        </SoftBox>
        <SoftBox sx={{ display: "flex", justifyContent: "end" }}>
          <SoftButton
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{
              py: "0px!important",
              paddingRight: "0px!important",
              "&:hover": {
                backgroundColor: "transparent!important",
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            Forgot password?
          </SoftButton>
        </SoftBox>

        <SoftBox mt={2} mb={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
          >
            sign in
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignIn;
