import React from "react";

import { Box, Grid } from "@mui/material";

import SoftBox from "../../../components/SoftBox";

import { TextInputField, MultipleSelectField, SelectInputField } from "components/FormField";
import { generateOptionsFromEnum } from "utils/options";
import { LoginTypes } from "constants/roles";
import { useGetPermissions } from "../users/hooks";

const RoleForm = () => {
  //hooks
  const { data: routers } = useGetPermissions();
  const routersOptions = _.map(routers, (r) => ({ label: r.name, value: r._id }));

  const loginTypesOptions = generateOptionsFromEnum(LoginTypes, false);

  return (
    <SoftBox p={3} pb={1}>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInputField label="Name" name="name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInputField label="Login Type" name="loginType" options={loginTypesOptions} />
          </Grid>
        </Grid>
        <SoftBox py={2}>
          <MultipleSelectField
            label="permission"
            name={`permissions`}
            options={routersOptions}
            maxMenuHeight={80}
            multiLine
          />
        </SoftBox>
      </Box>
    </SoftBox>
  );
};

export default RoleForm;
