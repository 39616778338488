// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

function SummaryItem({ icon, title, amount, symbol, total }) {
  const { size } = typography;
  const amountInt = new Intl.NumberFormat("en-US").format(amount);
  const percentage = Number((amount / total) * 100).toFixed(2);

  return (
    <SoftBox width="100%" py={1} mb={1}>
      <SoftBox display="flex" justifyContent="space-between" mb={1}>
        <SoftBox display="flex" alignItems="center" lineHeight={0}>
          <SoftBox mr={1} color={icon.color} fontSize={size.lg}>
            {icon.component}
          </SoftBox>
          <SoftTypography variant="button" fontWeight="medium" color="text">
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium" color="text">
          {amountInt} ({percentage}%) {symbol}
        </SoftTypography>
      </SoftBox>
      <SoftProgress value={(amount / total) * 100} color="dark" />
    </SoftBox>
  );
}

// Typechecking props for the SummaryItem
SummaryItem.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  symbol: PropTypes.string,
  total: PropTypes.number.isRequired,
};

export default SummaryItem;
