import { generateISODateRange } from "utils/time";
import * as Yup from "yup";

const sanitizeStatuses = (statuses) => {
  if (statuses) {
    if (statuses.includes("ALL") || statuses?.length === 0) {
      return null;
    }
    return statuses;
  }
  return null;
};
const sanitizePsps = (pspIds) => {
  if (pspIds) {
    if (pspIds.includes("") || pspIds?.length === 0) {
      return null;
    } else {
      return pspIds;
    }
  }
  return null;
};

const sanitizeBrands = (brandIds) => {
  if (brandIds) {
    if (brandIds.includes("") || brandIds?.length === 0) {
      return null;
    } else {
      return brandIds;
    }
  }
  return null;
};

const sanitizeType = (type) => {
  if (type) {
    if (type.includes("") || type?.length === 0) {
      return null;
    } else {
      return type;
    }
  }
  return null;
};

const sanitizeExport = ({
  merchantId,
  brands,
  statuses,
  pspIds,
  type,
  timeFrame,
  fromDate,
  toDate,
  additionalTransactions,
}) => {
  let _fromDate = null;
  let _toDate = null;
  if (timeFrame) {
    const dateRange = generateISODateRange(timeFrame);
    _fromDate = dateRange.fromDate;
    _toDate = dateRange.toDate;
  } else {
    _fromDate = fromDate;
    _toDate = toDate;
  }

  const payload = {
    merchant_id: merchantId,
    brand_ids: sanitizeBrands(brands),
    psp_ids: sanitizePsps(pspIds),
    type: sanitizeType(type),
    statuses: sanitizeStatuses(statuses),
    start_date: _fromDate,
    end_date: _toDate,
    additional_transaction_ids: additionalTransactions?.replace(" ", "").split(","),
  };
  return _.pickBy(payload, _.identity);
};

export const sanitizeRequest = ({ exports }, psps) => {
  const requestArray = [];
  exports.forEach((exportItem) => {
    const payload = sanitizeExport(exportItem);
    if (!_.isEmpty(payload)) {
      requestArray.push(payload);
    }
  });
  return { exports: requestArray };
};

// schemas

export const defaultValues = {
  exports: [],
};

export const schema = Yup.object().shape({
  exports: Yup.array()
    .of(
      Yup.object()
        .shape({
          fromDate: Yup.date().nullable(),
          toDate: Yup.date().nullable(),
          merchantId: Yup.string().required("Merchant ID is required"),
          pspIds: Yup.array().of(Yup.string()).nullable(),
          brands: Yup.array().of(Yup.string()).nullable(),
          type: Yup.array().nullable(),
          timeFrame: Yup.string().nullable(),
          statuses: Yup.array()
            .of(
              Yup.string().oneOf(
                [
                  "REFUNDED",
                  "DECLINED",
                  "USER_ABANDONED",
                  "EXPIRED",
                  "ERROR",
                  "USER_CANCEL",
                  "COMPLETED",
                  "CAPTURED",
                  "CHARGEBACK",
                  "VOIDED",
                  "PENDING",
                ],
                "Invalid status"
              )
            )
            .nullable(),
          additionalTransactions: Yup.array().of(Yup.string()).nullable(),
        })
        .test("date-or-timeframe", function (value, index) {
          const { fromDate, toDate, timeFrame } = value;
          if ((fromDate && toDate) || timeFrame) {
            return true;
          }
          return this.createError({
            path: `${index.path}.datetime`,
            message: `Either dates or timeframe must be provided`,
          });
        })
    )
    .min(1, "Exports must have at least one item")
    .required("Exports is required"),
});
