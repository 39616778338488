import React, { useEffect } from "react";

import {
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Tooltip,
  Box,
  Grid,
  DialogActions,
} from "@mui/material";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema, sanitizeRequest } from "pages/settings/users/schemas/user";
import FormProvider from "components/FormProviders";
import { useDisclosure } from "hooks/useDisclosure";
import { useGetUserRoles, useUpdateUserRoles } from "pages/settings/users/hooks";
import { LoginTypes } from "constants/roles";
import { TextInputField, MultipleSelectField } from "components/FormField";
import { generateOptions } from "utils/options";
import { useQueryClient } from "react-query";
import { ORGANIZATION_USERS } from "../hooks";

const EditUserModal = ({ email, name, userId, currentRoles }) => {
  const { id: merchantId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  //hooks
  const { isLoading, mutate } = useUpdateUserRoles();
  const { data: roles } = useGetUserRoles({ loginType: LoginTypes.ORGANIZATION });
  const rolesOptions = generateOptions(roles, false);

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(sanitizeRequest({ ...values, userId }), {
      onSuccess: () => {
        queryClient.invalidateQueries([ORGANIZATION_USERS, merchantId]);
        onClose();
      },
    });
  };

  useEffect(() => {
    const rolesStr = _.map(currentRoles, (r) => r._id);
    reset({
      email,
      name,
      roles: rolesStr,
    });
  }, [email, name, userId, currentRoles]);

  return (
    <>
      <Tooltip title="Update User">
        <IconButton onClick={onOpen} disabled={isLoading} color="secondary">
          <EditIcon sx={{ ml: 2 }} />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Update User Role
          </DialogTitle>
          <DialogContent sx={{ minHeight: "250px" }}>
            <SoftBox p={3} pb={1}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextInputField label="Name" name="name" disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInputField label="Email" name="email" disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <MultipleSelectField
                      name="roles"
                      label="Roles"
                      options={rolesOptions}
                      placeholder="Select roles"
                      maxMenuHeight={70}
                    />
                  </Grid>
                </Grid>
              </Box>
            </SoftBox>
          </DialogContent>

          <DialogActions>
            <SoftButton onClick={onClose}>Cancel</SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Update User
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
EditUserModal.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  currentRoles: PropTypes.array,
  userId: PropTypes.string,
};
export default EditUserModal;
