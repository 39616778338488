import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Settings page components
import PspMidGrid from "./components/PspMidGrid";
import PspMidDetail from "./components/PspMidDetail";

import { hasPermissions } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";

function PspMids() {
  if (!hasPermissions([Permissions.PSP.LIST, Permissions.BRAND_MID.LIST])) {
    return <Error403 />;
  }
  const [tab, setTab] = useState("grid");
  const [midId, setMidId] = useState("");
  const [midName, setMidName] = useState("");

  const handleTabChange = (midId, midName) => {
    setMidId(midId);
    setMidName(midName);
    setTab("detail");
  };
  const handleDetailBackClick = () => {
    setTab("grid");
  };

  return (
    <SoftBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {tab === "grid" && <PspMidGrid onEditClick={handleTabChange} />}
                {tab === "detail" && (
                  <PspMidDetail
                    pspMidId={midId}
                    midName={midName}
                    onBackBtnClick={handleDetailBackClick}
                  />
                )}
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default PspMids;
