import * as Yup from "yup";

export const defaultValues = {
  roles: null,
};

export const schema = Yup.object().shape({
  roles: Yup.array().required("roles are required"),
});

export const sanitizeRequest = ({ roles, userId }) => {
  return { roles, userId };
};
