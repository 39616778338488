import React from "react";

import { Dialog, DialogContent, DialogTitle, Stack, Grid } from "@mui/material";
import FormProvider from "components/FormProviders";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDisclosure } from "hooks/useDisclosure";
import {
  TextInputField,
  DatePickerField,
  MultipleSelectField,
  CurrencySelect,
  TimeRangeInput,
} from "components/FormField";
import { useForm } from "react-hook-form";
import { generateOptions } from "utils/options";
import { dateTimePickerOptions } from "constants/dateFilter";
import { defaultValues, schema, sanitizeRequest } from "../schemas/fxRate";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSetHistoricalRate } from "../hooks";
import { requestQueue } from "utils/requestQueue";

const FxRate = ({ merchants }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const merchantOptions = generateOptions(merchants);

  // query
  const { mutateAsync: setHistoricalRate, isLoading } = useSetHistoricalRate();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setValue } = methods;
  const onSubmit = async (values) => {
    await requestQueue(sanitizeRequest(values), setHistoricalRate).then((res) => {
      onClose();
    });
  };
  const clearDateSelect = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
  };
  const clearTimeFrame = () => {
    setValue("last", "");
  };
  return (
    <>
      <SoftButton
        variant="outlined"
        color="text"
        type="submit"
        sx={{ mt: 2, ml: 2 }}
        onClick={onOpen}
      >
        Fx Rate
      </SoftButton>
      <Dialog open={isOpen} onClose={onClose} sx={{ overflow: "visible" }} fullWidth maxWidth="lg">
        <DialogTitle>Fx Rate</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SoftBox p={2}>
              <Grid container spacing={3}>
                <Grid xs={12} sx={12} item>
                  <MultipleSelectField
                    name="merchants"
                    label="Merchants"
                    options={merchantOptions}
                    isClearable
                    maxMenuHeight={200}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <TextInputField name="rate" label="Rate" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CurrencySelect name="fromCurrency" label="From Currency" />
                </Grid>
                <Grid item xs={6}>
                  <CurrencySelect name="toCurrency" label="To Currency" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid xs={4} item>
                  <DatePickerField
                    name="fromDate"
                    options={dateTimePickerOptions()}
                    label="From Date"
                    onChange={clearTimeFrame}
                  />
                </Grid>
                <Grid xs={4} item>
                  <DatePickerField
                    name="toDate"
                    options={dateTimePickerOptions()}
                    label="To Date"
                    onChange={clearTimeFrame}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TimeRangeInput name="timeFrame" label="Time frame" onChange={clearDateSelect} />
                </Grid>
              </Grid>

              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={onClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FxRate;

// Typechecking props of the ReconciliationFilters
FxRate.propTypes = {
  merchants: PropTypes.array,
};
