import React, { useState } from "react";
import PropTypes from "prop-types";

import { Grid, CircularProgress, IconButton, Icon } from "@mui/material";
import {
  DatePickerField,
  MultipleSelectField,
  SelectInputField,
  TextInputField,
  TimeRangeInput,
} from "components/FormField";
import SoftTypography from "components/SoftTypography";
import { isoDateTimePickerOptions } from "constants/dateFilter";

import { useFormContext } from "react-hook-form";

import { statusOptions, typeOptions } from "constants/reconciliation";
import { generateOptions } from "utils/options";
import { useGetBrands } from "../hooks";
import SoftBox from "components/SoftBox";
import CollapseWrapper from "components/FormField/CollapseWrapper";

const ExportSelectForm = ({ merchants, psps, nestedIndex, remove }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const datetimeError = _.get(errors, `exports[${nestedIndex}].datetime`, null);
  const { mutate: getBrands, isLoading: isFetchingBrand } = useGetBrands();
  const [title, setTitle] = useState(`Export ${nestedIndex + 1}`);

  const pspsOptions = generateOptions(psps);
  const merchantOptions = generateOptions(merchants, false);
  const [brandOptions, setBrandOptions] = useState([]);
  const clearTimeFrame = () => {
    setValue(`exports[${nestedIndex}].timeFrame`, "");
  };
  const clearDateSelect = () => {
    setValue(`exports[${nestedIndex}].fromDate`, null);
    setValue(`exports[${nestedIndex}].toDate`, null);
  };
  const onMerchantChange = (selected) => {
    getBrands(
      { merchantId: selected?.value },
      {
        onSuccess: (data) => {
          setValue(`exports[${nestedIndex}].brands`, []);
          setBrandOptions(generateOptions(data, true));
          setValue(`exports[${nestedIndex}].brands`, [""]);
        },
      }
    );
    setTitle(`Export ${nestedIndex + 1} - ${selected?.label}`);
  };
  return (
    <CollapseWrapper
      title={title}
      openDefault={true}
      additionalActionNode={
        <SoftBox>
          <IconButton onClick={() => remove(nestedIndex)} sx={{ mt: 2 }}>
            <Icon color="error">delete_forever</Icon>
          </IconButton>
        </SoftBox>
      }
    >
      <SoftBox p={3} display="flex">
        <SoftBox p={3} />

        <Grid container spacing={2} display="flex" alignItems="center" justifyContent={"start"}>
          {datetimeError && !_.isArray(datetimeError) && (
            <Grid xs={12} mt={2} sm={12}>
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  {_.get(datetimeError, "message", "Unknown error")}
                </SoftTypography>
              </SoftBox>
            </Grid>
          )}
          <Grid xs={11.5} mt={2} sm={11.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SelectInputField
                  options={merchantOptions}
                  name={`exports[${nestedIndex}].merchantId`}
                  label="Merchant"
                  onSelect={onMerchantChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {isFetchingBrand && (
                  <SoftBox mt={3}>
                    <CircularProgress color="secondary" size="30px" />
                  </SoftBox>
                )}
                {!isFetchingBrand && (
                  <MultipleSelectField
                    options={brandOptions}
                    name={`exports[${nestedIndex}].brands`}
                    label="Brands"
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <MultipleSelectField
                  options={typeOptions}
                  name={`exports[${nestedIndex}].type`}
                  label="Type"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MultipleSelectField
                  options={pspsOptions}
                  name={`exports[${nestedIndex}].pspIds`}
                  label="Psps"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MultipleSelectField
                  options={statusOptions}
                  name={`exports[${nestedIndex}].statuses`}
                  label="Statuses"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name={`exports[${nestedIndex}].fromDate`}
                  options={isoDateTimePickerOptions()}
                  label="From Date"
                  onChange={clearTimeFrame}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  name={`exports[${nestedIndex}].toDate`}
                  options={isoDateTimePickerOptions()}
                  label="To Date"
                  onChange={clearTimeFrame}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TimeRangeInput
                  name={`exports[${nestedIndex}].timeFrame`}
                  label="Time frame"
                  onChange={clearDateSelect}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextInputField
                  name={`exports[${nestedIndex}].additionalTransactions`}
                  label="Additional transactions"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </CollapseWrapper>
  );
};
ExportSelectForm.propTypes = {
  nestedIndex: PropTypes.number,
  merchants: PropTypes.array,
  psps: PropTypes.array,
  remove: PropTypes.func,
};

export default ExportSelectForm;
