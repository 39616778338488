export const TypeEnum = {
  CARD: "CARD",
  BANK: "BANK",
  APM: "APM",
  P2P: "P2P",
};

export const typeOptions = [
  { label: "CARD", value: "CARD" },
  { label: "BANK", value: "BANK" },
  { label: "APM", value: "APM" },
  { label: "P2P", value: "P2P" },
];

export const statusOptions = [
  { label: "ALL", value: "ALL" },
  { label: "REFUNDED", value: "REFUNDED" },
  { label: "DECLINED", value: "DECLINED" },
  { label: "USER_ABANDONED", value: "USER_ABANDONED" },
  { label: "EXPIRED", value: "EXPIRED" },
  { label: "ERROR", value: "ERROR" },
  { label: "USER_CANCEL", value: "USER_CANCEL" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "CAPTURED", value: "CAPTURED" },
  { label: "CHARGEBACK", value: "CHARGEBACK" },
  { label: "VOIDED", value: "VOIDED" },
  { label: "PENDING", value: "PENDING" },
];

export const patchExportStatusOptions = [
  { label: "REJECTED", value: "REJECTED" },
  { label: "APPROVED", value: "APPROVED" },
];

export const reconFilterStatusOption = [
  { label: "ALL", value: "ALL" },
  { label: "REJECTED", value: "REJECTED" },
  { label: "APPROVED", value: "APPROVED" },
  { label: "PROCESSING", value: "PROCESSING" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "FAILED", value: "FAILED" },
];
