import { useQueryClient } from "react-query";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  TextInputField,
  SelectInputField,
  DatePickerField,
  PaymentOptionSelect,
  CurrencySelect,
  TimeRangeInput,
} from "components/FormField";
import { useFormContext } from "react-hook-form";
import { GET_BRANDS } from "pages/merchant-management-detail/hooks";
import { categoryOptions, statusOptions, typeOptions } from "constants/status";
import PropTypes from "prop-types";
import SortedCountrySelect from "../../../components/SortedCountrySelect";
import { dateTimePickerOptions } from "constants/dateFilter";
import { generateOptions, generateOptionsFromErrorCodes } from "utils/options";

const FilterForm = (props) => {
  const { paymentOptions, pspsData, merchants, brands, errorCodes } = props;
  const [paymentOptionsData, setPaymentOptionsData] = useState([]);
  const [pspOptionsData, setPspsData] = useState([]);
  const [brandOptionsData, setBrandOptionsData] = useState([]);
  const [merchantOptionsData, setMerchantOptionsData] = useState([]);
  const [errorCodesOptions, setErrorCodesOptions] = useState([]);

  const { watch, setValue } = useFormContext();
  const queryClient = useQueryClient();

  const watchPaymentOption = watch("paymentOptionId", null);
  const watchPsp = watch("pspId", null);

  useEffect(() => {
    if (pspsData) setPspsData(generateOptions(pspsData));
  }, [pspsData]);
  useEffect(() => {
    if (paymentOptions) setPaymentOptionsData(paymentOptions);
  }, [paymentOptions]);
  useEffect(() => {
    if (merchants) setMerchantOptionsData(generateOptions(merchants));
  }, [merchants]);
  useEffect(() => {
    if (brands) setBrandOptionsData(generateOptions(brands));
  }, [brands]);
  useEffect(() => {
    if (errorCodes) setErrorCodesOptions(generateOptionsFromErrorCodes(errorCodes));
  }, [errorCodes]);

  useEffect(() => {
    const paymentOption = paymentOptionsData?.find((i) => i.id === watchPaymentOption)?.name;
    setValue("paymentOptionName", paymentOption);
    if (watchPaymentOption === "") {
      setValue("paymentOptionName", "");
      setValue("paymentOptionId", "");
    }
  }, [watchPaymentOption, paymentOptionsData]);
  useEffect(() => {
    const psp = pspOptionsData?.find((i) => i.value === watchPsp)?.label;
    if (psp) setValue("pspName", psp);
    if (watchPsp === "") {
      setValue("pspName", "");
      setValue("pspId", "");
    }
  }, [watchPsp, pspOptionsData]);

  const clearDateSelect = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
  };
  const clearLast = () => {
    setValue("last", "");
  };

  return (
    <div>
      {" "}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Search" name="search" placeholder="Search for transactions" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PaymentOptionSelect
            name="paymentOptionId"
            label="Payment Option"
            paymentOptions={paymentOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectInputField
            label="Merchants"
            name="merchantId"
            message="Required"
            options={merchantOptionsData}
            placeholder="Select an option"
            onSelect={(e) => {
              queryClient.invalidateQueries([GET_BRANDS, e.value]);
              setValue("merchantId", e.value);
              setValue("merchantName", merchantOptionsData.find((i) => i.value === e.value)?.label);
              setValue("brandId", "");
              setValue("brandName", "");
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectInputField
            name="brandId"
            label="Brand"
            message="Required"
            options={brandOptionsData}
            onSelect={(e) => {
              setValue("brandId", e.value);
              setValue("brandName", brandOptionsData.find((i) => i.value === e.value)?.label);
            }}
            placeholder="Select brand"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextInputField
            label="Min Amount"
            name="minAmount"
            type="number"
            placeholder="Minimum amount"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField
            label="Max Amount"
            name="maxAmount"
            type="number"
            placeholder="Maximum amount"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectInputField
            options={errorCodesOptions}
            label="Error Code"
            name="errorCode"
            type="number"
            placeholder="Error Code"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <SortedCountrySelect name={"country"} label="Country" setValue={setValue} withAll />
        </Grid>
        <Grid item sm={4}>
          <CurrencySelect name={"currency"} label={"Currency"} withAll />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectInputField
            name="status"
            label="Status"
            options={statusOptions}
            placeholder="Select an status"
            sx={{ margin: "0" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <SelectInputField
            name="pspId"
            label="Psp"
            options={pspOptionsData}
            placeholder="Select a status"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectInputField
            name="type"
            label="Type"
            options={typeOptions}
            placeholder="Select a type"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectInputField
            name="category"
            label="Category"
            options={categoryOptions}
            placeholder="Select a category"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Customer Name" name="customerName" placeholder="Customer name" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField label="Email" name="email" placeholder="Customer email" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputField label="PAN" name="pan" placeholder="PAN or Masked PAN" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <DatePickerField
            name="fromDate"
            options={dateTimePickerOptions()}
            label="From Date"
            onChange={clearLast}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerField
            name="toDate"
            options={dateTimePickerOptions()}
            label="To Date"
            onChange={clearLast}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TimeRangeInput name="last" label="Timeframe" onChange={clearDateSelect} />
        </Grid>
      </Grid>
    </div>
  );
};

export default FilterForm;

// Typechecking props of the TransactionFilters
FilterForm.propTypes = {
  paymentOptions: PropTypes.object,
  pspsData: PropTypes.object,
  merchants: PropTypes.object,
  brands: PropTypes.object,
  errorCodes: PropTypes.object,
};
