import React from "react";
import Shop from "examples/Icons/Shop";
import FilterList from "@mui/icons-material/FilterList";
import MismatchedTransactions from "pages/mismatched-transactions";
import SettingsIcon from "@mui/icons-material/Settings";
import SignpostIcon from "@mui/icons-material/Signpost";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HandshakeIcon from "@mui/icons-material/Handshake";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BarChartIcon from "@mui/icons-material/BarChart";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PaymentIcon from "@mui/icons-material/Payment";
import GroupIcon from "@mui/icons-material/Group";
import ErrorIcon from "@mui/icons-material/Error";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import BusinessIcon from "@mui/icons-material/Business";

import Transactions from "pages/transactions";
import MerchantManagement from "pages/merchant-management";
import PspManagement from "pages/psp-management";
import TransactionManagement from "pages/transaction-management";
import Reconciliation from "pages/reconciliation";
import Settings from "./pages/settings";
import CardsManagement from "./pages/cards-management";
import CustomersManagement from "./pages/customers-management";
import MerchantsSummary from "./pages/merchants-summary";
import ErrorMapping from "pages/error-mapping";
import UserLogs from "pages/user-logs";
import { PagePermissions } from "constants/roles";
import ErrorSummary from "pages/error-summary";
import TransactionsBatches from "pages/transactions-batch";
import TransactionAttempts from "pages/transaction-attempts";
import Organizations from "pages/organizations";

const routes = [
  {
    type: "collapse",
    name: "Organizations",
    key: "organizations",
    icon: <BusinessIcon size="12px" />,
    route: "/organizations",
    noCollapse: true,
    component: <Organizations />,
    permissionsRequired: PagePermissions.Organizations,
  },
  {
    type: "collapse",
    name: "Merchant management",
    key: "merchants",
    icon: <Shop size="12px" />,
    route: "/merchants",
    noCollapse: true,
    component: <MerchantManagement />,
    permissionsRequired: PagePermissions.MerchantManagement,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Reports", key: "title-reports" },
  {
    type: "collapse",
    name: "Transaction Reporting",
    key: "transactions",
    route: "/transactions",
    icon: <BarChartIcon size="12px" />,
    noCollapse: true,
    component: <Transactions />,
    permissionsRequired: PagePermissions.Transaction,
  },
  {
    type: "collapse",
    name: "Transaction Batch",
    key: "transactions-batch",
    route: "/transactions-batch",
    icon: <FilterList size="12px" />,
    noCollapse: true,
    component: <TransactionsBatches />,
    permissionsRequired: PagePermissions.Transaction,
  },
  {
    type: "collapse",
    name: "Transaction Management",
    key: "transaction-management",
    route: "/transaction-management",
    icon: <StorefrontIcon size="12px" />,
    noCollapse: true,
    component: <TransactionManagement />,
    permissionsRequired: PagePermissions.Transaction,
  },
  {
    type: "collapse",
    name: "Transaction Attempts",
    key: "transaction-attempts",
    route: "/transaction-attempts",
    icon: <SignpostIcon size="12px" />,
    noCollapse: true,
    component: <TransactionAttempts />,
    permissionsRequired: PagePermissions.Transaction,
  },
  {
    type: "collapse",
    name: "Merchants Summary",
    key: "merchant-summary",
    route: "/merchant-summary",
    icon: <AssessmentIcon size="12px" />,
    noCollapse: true,
    component: <MerchantsSummary />,
    permissionsRequired: PagePermissions.MerchantSummary,
  },
  {
    type: "collapse",
    name: "Error Summary",
    key: "error-summary",
    route: "/error-summary",
    icon: <PlaylistRemoveIcon size="12px" />,
    noCollapse: true,
    component: <ErrorSummary />,
    permissionsRequired: PagePermissions.ErrorSummary,
  },
  { type: "divider", key: "divider-2" },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Psps Management",
    key: "psps",
    route: "/psps",
    icon: <EngineeringIcon size="12px" />,
    noCollapse: true,
    component: <PspManagement />,
    permissionsRequired: PagePermissions.PspManagement,
  },
  {
    type: "collapse",
    name: "Mismatched Transactions",
    key: "mismatched-transactions",
    route: "/mismatched-transactions",
    icon: <QuestionMarkIcon size="12px" />,
    noCollapse: true,
    component: <MismatchedTransactions />,
    permissionsRequired: PagePermissions.MismatchTransaction,
  },
  {
    type: "collapse",
    name: "Cards Management",
    key: "cards",
    route: "/cards",
    icon: <PaymentIcon size="12px" />,
    noCollapse: true,
    component: <CardsManagement />,
    permissionsRequired: PagePermissions.CardManagement,
  },
  {
    type: "collapse",
    name: "Customers Management",
    key: "customers",
    route: "/customers",
    icon: <GroupIcon size="12px" />,
    noCollapse: true,
    component: <CustomersManagement />,
    permissionsRequired: PagePermissions.CustomerManagement,
  },
  {
    type: "collapse",
    name: "Reconciliation",
    key: "reconciliation",
    route: "/reconciliation",
    icon: <HandshakeIcon size="12px" />,
    noCollapse: true,
    component: <Reconciliation />,
    permissionsRequired: PagePermissions.Reconciliation,
  },
  {
    type: "collapse",
    name: "Error Mapping",
    key: "error-mapping",
    route: "/error-mapping",
    icon: <ErrorIcon size="12px" />,
    noCollapse: true,
    component: <ErrorMapping />,
    permissionsRequired: PagePermissions.ErrorCode,
  },
  {
    type: "collapse",
    name: "User Logs",
    key: "userLogs",
    route: "/user-logs",
    icon: <ListAltIcon size="12px" />,
    noCollapse: true,
    component: <UserLogs />,
    permissionsRequired: PagePermissions.Settings,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <SettingsIcon size="12px" />,
    noCollapse: true,
    component: <Settings />,
    permissionsRequired: PagePermissions.Settings,
  },
];

export default routes;
