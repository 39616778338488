import * as Yup from "yup";
const payinCredentialsDefault = {
  appId: "",
  secretKey: "",
  country: "",
  enabled: false,
};
const schemas = {
  ["6f9f0f92-06f5-4ab1-9bfd-145c7f27f2eb"]: {
    schema: null,
    defaultValues: null,
  },
  ["71afea8c-7b50-4871-85eb-87ae66982e4d"]: {
    schema: Yup.object().shape({
      mid: Yup.string().required("MID is required"),
      apiKey: Yup.string().required("API Key is required"),
      descriptorName: Yup.string(),
      descriptorUrl: Yup.string(),
      enabled: Yup.boolean(),
    }),
    defaultValues: {
      mid: null,
      apiKey: null,
      descriptorName: null,
      descriptorUrl: null,
      enabled: false,
    },
  },
  ["41b589c8-677d-4ef6-91a0-fe188d3fd075"]: {
    schema: Yup.object().shape({
      companyNum: Yup.string()
        .required("Company number is required")
        .max(100, "Company number is too long, should be less than 100 characters"),
      secretKey: Yup.string()
        .required("Secret key is required")
        .max(100, "Secret key is too long, should be under 100 characters"),
    }),
    defaultValues: {
      companyNum: null,
      secretKey: null,
    },
  },
  ["4c60f035-b19e-4e97-87fe-fe0260faf663"]: {
    schema: null,
    defaultValues: null,
  },
  ["4269aa7c-cdae-423c-9030-0a794e0be240"]: {
    schema: Yup.object().shape({
      payoutCredential: Yup.object().shape({
        appId: Yup.string(),
        appKey: Yup.string(),
        enabled: Yup.boolean(),
      }),
      payinCredentials: Yup.array()
        .of(
          Yup.object().shape({
            appId: Yup.string().default(payinCredentialsDefault.appId),
            secretKey: Yup.string().default(payinCredentialsDefault.secretKey),
            country: Yup.string().default(payinCredentialsDefault.country),
            enabled: Yup.boolean().default(payinCredentialsDefault.enabled),
          })
        )
        .default([payinCredentialsDefault]),
    }),
    defaultValues: {
      payoutCredential: {},
      payinCredentials: [],
    },
  },
  ["3b0d3fe0-5206-4640-82ad-333308a25780"]: {
    schema: Yup.object().shape({
      mid: Yup.string().required("MID is required"),
      serverKey: Yup.string().required("Server key is required"),
      enabled: Yup.boolean(),
      acquirers: Yup.array().of(
        Yup.object().shape({
          domainName: Yup.string(),
          currencies: Yup.array().of(Yup.string()),
          minScore: Yup.number(),
          maxScore: Yup.number(),
          enabled: Yup.boolean(),
          isSecure: Yup.boolean(),
        })
      ),
    }),
    defaultValues: {
      mid: null,
      serverKey: null,
      enabled: false,
      acquirers: [],
    },
  },
  ["d2336afc-1e8c-473b-8182-41cd3ed26529"]: {
    schema: Yup.object().shape({
      clientId: Yup.string()
        .required("Client ID is required")
        .max(100, "App ID is too long, should be less than 100 characters"),
      clientSecret: Yup.string()
        .required("Client key is required")
        .max(100, "Client key is too long, should be under 100 characters"),
      enabled: Yup.boolean(),
    }),
    defaultValues: {
      clientId: null,
      clientSecret: null,
      enabled: false,
    },
  },
  ["5d8c9938-3d8e-42fe-8a06-76196b4ddc7b"]: {
    schema: Yup.object().shape({
      apiKey: Yup.string()
        .required("API ID is required")
        .max(100, "API ID is too long, should be less than 100 characters"),
      signatureKey: Yup.string()
        .required("Signature key is required")
        .max(100, "Signature key is too long, should be under 100 characters"),
      enabled: Yup.boolean(),
    }),
    defaultValues: {
      apiKey: null,
      signatureKey: null,
      enabled: false,
    },
  },
  ["e60ebac2-3976-438b-a7ab-4fab89e646a4"]: {
    schema: Yup.object().shape({
      merchant: Yup.string().required("Merchant is required"),
      callbackApiKey: Yup.string().required("Callback API key is required"),
      enabled: Yup.boolean(),
      countryKeys: Yup.array().of(
        Yup.object().shape({
          apiKey: Yup.string(),
          contractName: Yup.string().required("Contract Name  is required"),
          country: Yup.string(),
          enabled: Yup.boolean(),
          payoutBankCode: Yup.string(),
        })
      ),
    }),
    defaultValues: {
      merchant: null,
      callbackApiKey: null,
      enabled: false,
      countryKeys: [],
    },
  },
  ["0063e91f-9b5b-444c-aa6f-ce5674db17ad"]: {
    schema: Yup.object().shape({
      externalMerchantId: Yup.string().required("External Merchant ID is required"),
      apiKey: Yup.string().required("API Key is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: { externalMerchantId: null, apiKey: null, enabled: false },
  },
  ["0ea3bc80-eae0-4567-89fb-64ac7faee7f8"]: {
    schema: Yup.object().shape({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
      payoutCustomerId: Yup.string().required("Payout Customer ID is required"),
      payoutApiKey: Yup.string().required("Payout API Key is required"),
      accounts: Yup.array().of(
        Yup.object().shape({
          accountNumber: Yup.string(),
          accountCode: Yup.string(),
          enabled: Yup.boolean(),
          currency: Yup.string(),
          country: Yup.string(),
        })
      ),
      enabled: Yup.boolean(),
    }),
    defaultValues: {
      username: null,
      password: null,
      payoutCustomerId: null,
      payoutApiKey: null,
      accounts: [],
      enabled: false,
    },
  },
  ["e211d399-861f-40b1-9716-b527e4d69e59"]: {
    schema: Yup.object().shape({
      apiUserName: Yup.string().required("API Username is required"),
      apiPassword: Yup.string().required("API Password is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: {
      apiUserName: null,
      apiPassword: null,
      enabled: false,
    },
  },
  ["9f7d63ca-3ec8-4a11-b5c9-f3836964f4f7"]: {
    schema: Yup.object().shape({
      apiKey: Yup.string().required("API Key is required"),
      shopId: Yup.string().required("Shop ID is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: {
      apiKey: null,
      shopId: null,
      enabled: false,
    },
  },
  ["7e2cde8f-3e91-4dd9-b68b-a2bb1a548873"]: {
    schema: Yup.object().shape({
      apiKey: Yup.string().required("API Key is required"),
      mid: Yup.number().required("MID is required"),
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: { apiKey: null, mid: null, email: null, password: null, enabled: false },
  },
  ["a2488a30-2e6b-4672-bc20-3e97131b049b"]: {
    schema: Yup.object().shape({
      clientId: Yup.string().required("Client ID is required"),
      clientSecret: Yup.string().required("Client Secret is required"),
      ivKey: Yup.string().required("IV Key is required"),
      secretKey: Yup.string().required("Secret Key is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: {
      clientId: null,
      clientSecret: null,
      ivKey: null,
      secretKey: null,
      enabled: false,
    },
  },
  ["85e999d1-5787-46d4-b280-713160278641"]: {
    schema: Yup.object().shape({
      appId: Yup.string()
        .required("App ID is required")
        .max(100, "App ID is too long, should be less than 100 characters"),
      saltKey: Yup.string()
        .required("Salt key is required")
        .max(100, "Salt key is too long, should be under 100 characters"),
    }),
    defaultValues: { appId: null, saltKey: null },
  },
  ["8eb950cb-cbd4-40f1-b0b8-5c5011b7aca2"]: {
    schema: Yup.object().shape({
      companyNum: Yup.string()
        .required("Company number is required")
        .max(100, "Company number is too long, should be less than 100 characters"),
      secretKey: Yup.string()
        .required("Secret key is required")
        .max(100, "Secret key is too long, should be under 100 characters"),
    }),
    defaultValues: { companyNum: null, secretKey: null },
  },
  ["a60441c3-4d4b-45b2-9f50-84204f1260ac"]: {
    schema: Yup.object().shape({
      terminalId: Yup.string().required("Terminal ID is required"),
      secretKey: Yup.string().required("Secret Key is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: { terminalId: null, secretKey: null, enabled: false },
  },
  ["84c61062-14b1-459b-9625-adb98a149e45"]: {
    schema: Yup.object().shape({
      merchantNumber: Yup.string()
        .required("Merchant number is required")
        .max(100, "Merchant number is too long, should be less than 100 characters"),
      secretKey: Yup.string()
        .required("Secret key is required")
        .max(100, "Secret key is too long, should be under 100 characters"),
    }),
    defaultValues: { merchantNumber: null, secretKey: null },
  },
  ["967df429-c93a-45f3-b929-448119ca62d3"]: {
    schema: Yup.object().shape({
      sid: Yup.string().required("SID is required"),
      payoutSid: Yup.string().required("Payout SID is required"),
      payoutApiKey: Yup.string().required("Payout API Key is required"),
    }),
    defaultValues: { sid: null, payoutSid: null, payoutApiKey: null },
  },
  ["3a07439b-71f5-427a-bf38-0463323e9e13"]: {
    schema: Yup.object().shape({
      publicKey: Yup.string().required("Public Key is required"),
      secretKey: Yup.string().required("Secret Key is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: { publicKey: null, secretKey: null, enabled: false },
  },
  ["4fbac749-beba-4c1b-b856-a3ed8fc15032"]: {
    schema: Yup.object().shape({
      accessToken: Yup.string().required("Access Token is required"),
      campaignId: Yup.string().required("Campaign ID is required"),
      securityToken: Yup.string().required("Security Token is required"),
      enabled: Yup.boolean(),
    }),
    defaultValues: { accessToken: null, campaignId: null, securityToken: null, enabled: false },
  },
  ["c6279cff-02cb-4408-bff8-03ad8c91bcb6"]: {
    schema: Yup.object().shape({
      applicationUuid: Yup.string().required("Application Uuid is required"),
      secret: Yup.string().required("Secret is required"),
      accounts: Yup.array().of(
        Yup.object().shape({
          bic: Yup.string(),
          currency: Yup.string(),
          country: Yup.string(),
          enabled: Yup.boolean(),
          iban: Yup.string(),
          address: Yup.object().shape({
            postCode: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
            addressLine: Yup.string(),
          }),
        })
      ),
    }),
    defaultValues: {
      applicationUuid: null,
      secret: null,
      payoutCustomerId: null,
      payoutApiKey: null,
      accounts: [],
    },
  },
  ["131202e8-b933-49be-9a50-ce7325af2d87"]: {
    schema: Yup.object().shape({
      apikey: Yup.string()
        .required("API ID is required")
        .max(100, "API ID is too long, should be less than 100 characters"),
    }),
    defaultValues: { apikey: null },
  },
  ["ed6d5ab1-c61b-44bb-893c-b8503ee0ebca"]: {
    schema: Yup.object().shape({
      secretKey: Yup.string().required("Secret Key is required"),
      brandId: Yup.string().required("Brand ID is required"),
    }),
    defaultValues: { brandId: null, secretKey: null },
  },
  ["19fd94b3-d8cb-4fc4-ba2d-2c8fbe746877"]: {
    schema: Yup.object().shape({
      apiKey: Yup.string()
        .required("API ID is required")
        .max(100, "API ID is too long, should be less than 100 characters"),
      secret: Yup.string().required("Secret is required"),
    }),
    defaultValues: { apiKey: null, secret: null },
  },
  ["9c58ced7-e128-4b41-9828-232555fa513f"]: {
    schema: Yup.object().shape({
      apiKey: Yup.string()
        .required("API ID is required")
        .max(100, "API ID is too long, should be less than 100 characters"),
    }),
    defaultValues: { apiKey: null },
  },
  ["eb1d099e-cef2-4c85-8d70-e8e544a40277"]: {
    schema: Yup.object().shape({
      companyNum: Yup.string()
        .required("Company number is required")
        .max(100, "Company number is too long, should be less than 100 characters"),
      secretKey: Yup.string()
        .required("Secret key is required")
        .max(100, "Secret key is too long, should be under 100 characters"),
      apiKey: Yup.string()
        .required("API ID is required")
        .max(100, "API ID is too long, should be less than 100 characters"),
    }),
    defaultValues: { apiKey: null, companyNum: null, secretKey: null },
  },
  ["766a4a16-fdda-4b33-818b-26b3c56f00ec"]: {
    schema: Yup.object().shape({
      apiKey: Yup.string()
        .required("API ID is required")
        .max(100, "API ID is too long, should be less than 100 characters"),
      signatureKey: Yup.string()
        .required("Signature key is required")
        .max(100, "Signature key is too long, should be under 100 characters"),
      targetUrl: Yup.string()
        .required("Target URL key is required")
        .max(100, "Target URL key is too long, should be under 100 characters"),
    }),
    defaultValues: { apiKey: null, signatureKey: null, whiteLabelUrl: null, targetUrl: null },
  },
  ["dd446ec7-47b8-423f-b29e-dae2e2e8b98e"]: {
    schema: Yup.object().shape({
      publishable: Yup.string()
        .required("Publishable is required")
        .max(500, "Publishable is too long, should be less than 500 characters"),
      secret: Yup.string()
        .required("Secret is required")
        .max(500, "Secret is too long, should be less than 500 characters"),
    }),
    defaultValues: { publishable: null, secret: null },
  },
  ["ffbc93e1-c38c-4790-be26-48d770136a81"]: {
    schema: Yup.object().shape({
      merchantAccount: Yup.string()
        .required("Merchant Account is required")
        .max(100, "Merchant Account is too long, should be less than 100 characters"),
      apiKey: Yup.string().required("API Key is required"),
      targetUrl: Yup.string().required("Target URL is required"),
    }),
    defaultValues: { merchantAccount: null, apiKey: null },
  },
  ["00c55e3a-c720-411d-af79-8d7b7459f490"]: {
    schema: Yup.object().shape({
      accessKey: Yup.string()
        .required("Access Key is required")
        .max(500, "Access Key is too long, should be less than 500 characters"),
      secretKey: Yup.string().required("Secret Key is required"),
      paymentMethods: Yup.array().of(Yup.string()),
    }),
    defaultValues: { accessKey: null, secretKey: null, paymentMethods: [] },
  },
  ["856e75bb-5d7e-4213-98e0-48162ec8e0ae"]: {
    schema: Yup.object().shape({
      clientId: Yup.string()
        .required("Client ID is required")
        .max(500, "Client ID is too long, should be less than 500 characters"),
      clientSecret: Yup.string().required("Client Secret is required"),
    }),
    defaultValues: { clientId: null, clientSecret: null },
  },
  ["cf62279b-401c-4a3b-91d8-a59c59a65148"]: {
    schema: Yup.object().shape({
      apiKey: Yup.string().required("API Key is required"),
    }),
    defaultValues: { apiKey: null },
  },
  ["1395c526-edf2-472f-9e7d-3cd45c03b78c"]: {
    schema: Yup.object().shape({
      mid: Yup.string().required("Mid is required"),
      midQ: Yup.string().required("MidQ is required"),
      targetUrl: Yup.string().required("Target URL is required"),
    }),
    defaultValues: { mid: null, midQ: null, targetUrl: null },
  },
  ["bb2c4c8c-84fc-4e9d-936e-bcb01da2fe44"]: {
    schema: Yup.object().shape({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
      terminalToken: Yup.string().required("Terminal Token is required"),
      currency: Yup.string().required("Currency is required"),
    }),
    defaultValues: { username: null, password: null, terminalToken: null, currency: null },
  },
  ["4c60f035-b19e-4e97-87fe-fe0260faf663"]: {
    schema: Yup.object().shape({
      appId: Yup.string().required("App ID is required"),
      secret: Yup.string().required("Secret is required"),
      accounts: Yup.array().of(
        Yup.object().shape({
          iban: Yup.string(),
          currency: Yup.string(),
          name: Yup.string(),
          countryCode: Yup.string(),
        })
      ),
    }),
    defaultValues: { appId: null, secret: null },
  },
};

export const getDefaultValues = (pspId) => {
  return schemas[pspId]?.defaultValues ?? {};
};

export const getSchema = (pspId) => {
  return schemas[pspId]?.schema ?? {};
};
