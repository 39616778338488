import { reconFilterStatusOption } from "constants/reconciliation";
import _ from "lodash";
import * as Yup from "yup";

export const defaultValues = {
  merchant: null,
  type: null,
  status: null,
  brand_ids: null,
};

const sanitizeFilterStatuses = (statuses) => {
  if (statuses) {
    if (statuses.includes("ALL")) {
      return _.map(
        _.filter(reconFilterStatusOption, (s) => s.value != "ALL"),
        (s) => s.value
      );
    }
    return statuses;
  }
  return null;
};

export const sanitizeHistory = ({ status, statuses, ...payload }) => {
  const _status = sanitizeFilterStatuses(status);
  if (_status) payload.status = _status;
  return _.pickBy(payload, _.identity);
};

export const schema = Yup.object().shape({
  merchant: Yup.string().required("Merchant is required"),
});
