import { toastError } from "utils/toast";

export const requestQueue = (payloadMap, mutate) => {
  const requests = [];
  _.map(payloadMap, (payload) => {
    requests.push(
      mutate(payload, {
        onError: (error) => {
          toastError({
            description: `${_.get(payload, "merchant_id")} ${_.get(
              error,
              "response.data.detail",
              "Unexpected error"
            )}`,
          });
        },
      })
    );
  });
  return Promise.all(requests);
};
