import React from "react";

import { Dialog, DialogContent, DialogTitle, Stack, Grid } from "@mui/material";
import FormProvider from "components/FormProviders";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDisclosure } from "hooks/useDisclosure";
import { TextInputField, SelectInputField } from "components/FormField";
import { useForm } from "react-hook-form";
import { patchExportStatusOptions } from "constants/reconciliation";
import { usePatchExport } from "../hooks";
import { TreeNode } from "pages/mismatched-transaction-detail";

const PatchExportModal = ({ jobId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const methods = useForm();
  const { handleSubmit } = methods;
  const { mutate, data, isLoading } = usePatchExport();
  const onSubmit = (values) => {
    mutate({ jobId, ...values });
  };

  return (
    <>
      <SoftButton
        variant="gradient"
        color="secondary"
        type="button"
        size="small"
        sx={{ ml: 2 }}
        onClick={onOpen}
      >
        Patch
      </SoftButton>
      <Dialog open={isOpen} onClose={onClose} sx={{ overflow: "visible" }} fullWidth maxWidth="sm">
        <DialogTitle>Patch export</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SoftBox p={2}>
              <Grid>
                <Grid container spacing={2}>
                  <Grid xs={12} mt={2} ml={2}>
                    <SelectInputField
                      label="status"
                      name="status"
                      options={patchExportStatusOptions}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid xs={12} mt={2} ml={2}>
                    <TextInputField label="note" name="note" />
                  </Grid>
                </Grid>
                {data && (
                  <Grid container spacing={2}>
                    <Grid xs={12} mt={2} ml={2}>
                      <TreeNode
                        name="Summary"
                        value={_.get(data, "summary", {})}
                        openNodes={["data"]}
                        setOpenNodes={() => {}}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={onClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Patch
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
PatchExportModal.propTypes = {
  jobId: PropTypes.string,
};

export default PatchExportModal;
