import React, { useMemo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import StatusSummary from "./StatusSummary";
import Card from "@mui/material/Card";
import SoftTypography from "../../../components/SoftTypography";
import Tooltip from "@mui/material/Tooltip";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import ComplexReportsDoughnutChartItem from "../../../examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart/ComplexReportsDoughnutChartItem";
import { currenciesFlags } from "../../../constants/currenciesFlags";
import wave from "assets/images/shapes/pattern-lines.svg";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";

function CurrencyReport({ data }) {
  if (!hasPermission(Permissions.TRANSACTION.GET_SUMMARY)) {
    return <Error403 />;
  }

  const renderItems = data?.byCurrency
    ? data.byCurrency.map((item, key) => (
        <Grid item xs={12} lg={6} key={item.code + key}>
          <ComplexReportsDoughnutChartItem
            image={currenciesFlags[item.code.toLowerCase()] || wave}
            title={item.code}
            percentage={`${item.total}`}
            hasBorder={key !== data?.byCurrency.length - 1}
          />
        </Grid>
      ))
    : null;

  return (
    <SoftBox py={3}>
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3.5}>
            <StatusSummary data={data?.byStatus} currencyReport={true} />
          </Grid>
          <Grid item xs={12} lg={8.5}>
            <Card sx={{ height: "auto", padding: 2, marginBottom: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Payin Summary</SoftTypography>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ paddingTop: "0!important" }}>
                  <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
                    Total COMPLETED:{" "}
                    <span style={{ fontWeight: "bold" }}>${data?.overall?.completedSum}</span> in{" "}
                    <span style={{ fontWeight: "bold" }}>{data?.overall?.completedCount}</span>{" "}
                    transactions.
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ paddingTop: "0!important" }}>
                  <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
                    Total ATTEMPT:{" "}
                    <span style={{ fontWeight: "bold" }}>${data?.overall?.attemptSum}</span> in{" "}
                    <span style={{ fontWeight: "bold" }}>{data?.overall?.attemptCount}</span>{" "}
                    transactions.
                  </SoftTypography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginTop: "15px!important" }}>
                  <SoftTypography variant="h6">Payout Summary</SoftTypography>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ paddingTop: "0!important" }}>
                  <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
                    Total COMPLETED:{" "}
                    <span style={{ fontWeight: "bold" }}>${data?.payoutOverall?.completedSum}</span>{" "}
                    in{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.payoutOverall?.completedCount}
                    </span>{" "}
                    transactions.
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ paddingTop: "0!important" }}>
                  <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
                    Total ATTEMPT:{" "}
                    <span style={{ fontWeight: "bold" }}>${data?.payoutOverall?.attemptSum}</span>{" "}
                    in{" "}
                    <span style={{ fontWeight: "bold" }}>{data?.payoutOverall?.attemptCount}</span>{" "}
                    transactions.
                  </SoftTypography>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ height: "auto", padding: 2, marginBottom: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginTop: "0px!important" }}>
                  <SoftTypography variant="h6">Overall Summary</SoftTypography>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ paddingTop: "0!important" }}>
                  <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
                    Total COMPLETED:{" "}
                    <span style={{ fontWeight: "bold" }}>${data?.completedSum?.usdTotal}</span> in{" "}
                    <span style={{ fontWeight: "bold" }}>{data?.completedSum?.txCount}</span>{" "}
                    transactions.
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ paddingTop: "0!important" }}>
                  <SoftTypography variant="h6" sx={{ fontWeight: 400 }}>
                    Total ATTEMPT:{" "}
                    <span style={{ fontWeight: "bold" }}>${data?.overallAttempt?.usdTotal}</span> in{" "}
                    <span style={{ fontWeight: "bold" }}>{data?.overallAttempt?.txCount}</span>{" "}
                    transactions.
                  </SoftTypography>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ height: "100%" }}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={2}
                px={2}
              >
                <SoftTypography variant="h6">Summary by currency</SoftTypography>
                <Tooltip title="Summary amount by currency" placement="right">
                  <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>

              <SoftBox position="relative" p={2}>
                <Grid container display="flex" flexWrap="wrap" spacing={2}>
                  {useMemo(() => renderItems, [data])}
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}
// Setting default values for the props of SummaryReport
CurrencyReport.defaultProps = {
  data: {},
};

// Typechecking props of the SummaryReport
CurrencyReport.propTypes = {
  data: PropTypes.object,
};

export default CurrencyReport;
