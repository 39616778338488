import { Card, Divider, DialogActions } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import FormProvider from "components/FormProviders";
import { useGetMerchantInfo, useUpdateMerchantInfo } from "../../hooks";
import { useDeleteMerchant } from "pages/merchant-management/hooks";
import MerchantForm, { sanitizeRequest } from "pages/merchant-management/components/MerchantForm";

import { yupResolver } from "@hookform/resolvers/yup";
import { schema, defaultValues } from "pages/merchant-management/schemas/merchant";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import { DeleteModal } from "components/Modal";

const Profile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const hasReadPermission = hasPermission(Permissions.MERCHANT.GET);
  if (!hasReadPermission) {
    return <Error403 />;
  }

  // hooks
  const { isLoading: isDeleting, mutateAsync: deleteMerchant } = useDeleteMerchant();
  const { data } = useGetMerchantInfo(id);
  const { mutate, isLoading } = useUpdateMerchantInfo();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;

  const handleSubmitForm = (values) => {
    mutate(sanitizeRequest({ ...values, id }));
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  return (
    <SoftBox>
      <Card sx={{ overflow: "visible" }}>
        <SoftBox p={3}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <MerchantForm />
            <Divider />
            <DialogActions style={{ justifyContent: "flex-start" }}>
              <SoftButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 2 }}
                disabled={isLoading}
                isLoading={isLoading}
              >
                Update
              </SoftButton>
              <DeleteModal
                type="button"
                title="Delete Merchant"
                message={
                  <>
                    Are you sure you want to delete merchant <b>{_.get(data, "name")}</b>? you will
                    not be able to recover this merchant?
                  </>
                }
                isLoading={isDeleting}
                onDelete={async () => {
                  await deleteMerchant({ merchantId: _.get(data, "_id") });
                }}
                onClose={() => {
                  navigate("/merchants");
                }}
              />
            </DialogActions>
          </FormProvider>
        </SoftBox>
      </Card>
    </SoftBox>
  );
};

export default Profile;
