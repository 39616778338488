import { generateDateRange } from "utils/time";
import { fDateTimeSuffix } from "utils/formatTime";
import { countries } from "constants/countries";
import { currencies } from "constants/currencies";

export const updateSelectedFilters = (data, fn, options) => {
  const {
    toDate,
    country,
    fromDate,
    paymentOptionId,
    pspId,
    last,
    merchantId,
    brandId,
    transactionId,
    // eslint-disable-next-line no-unused-vars
    sortBy,
    // eslint-disable-next-line no-unused-vars
    sortDirection,
    minAmount,
    maxAmount,
    documentId,
    currency,
    brandName,
    pan,
    // eslint-disable-next-line no-unused-vars
    paymentOptionName,
    // eslint-disable-next-line no-unused-vars
    pspName,
    errorCode,
    searchArchives,
    includeDeleted,
    excludeAbandoned,
    effectiveAr,
    userId,
    requestId,
    transactionIds,
    ...selected
  } = data;
  const { paymentOptions, merchants, brands, psps } = options;
  let _toDate = toDate;
  let _fromDate = fromDate;

  if (last) {
    const dateRange = generateDateRange(last);
    _fromDate = dateRange.fromDate;
    _toDate = dateRange.toDate;
  }
  let time = { toDate: null, fromDate: null };
  if (_toDate) {
    time.toDate = fDateTimeSuffix(_toDate).split(" ");
    time.toDate.splice(2, 1);
  }
  if (_fromDate) {
    time.fromDate = fDateTimeSuffix(_fromDate).split(" ");
    time.fromDate.splice(2, 1);
  }
  let brandN;
  if (merchantId && brandId) brandN = brands?.find((i) => i._id === brandId)?.name;

  fn((prev) => {
    const paymentOption =
      paymentOptionId && paymentOptions?.find((item) => item.id === paymentOptionId);

    let psp = paymentOption && paymentOption.psps?.find((item) => item.id === pspId)?.name;

    if (psp == undefined || psp == null || _.isEmpty(psp)) {
      psp = psps && psps?.find((item) => item._id === pspId)?.name;
    }
    return {
      ...prev,
      ...selected,
      pspId: psp,
      country:
        country && country !== "All" && _.get(_.find(countries, { code: country }), "label", "N/A"),
      fromDate:
        _fromDate &&
        `From ${time.fromDate.join(" ") + (_toDate ? " to" + " " + time.toDate.join(" ") : "")} `,
      toDate: _fromDate ? null : _toDate && `To ${time.toDate.join(" ")}`,
      last: last && `${last}`,
      merchantName: merchants?.find((option) => option._id === merchantId)?.name,
      transactionId: transactionId && `Txn ID: ${transactionId}`,
      paymentOptionId: paymentOption && paymentOptionId && paymentOption.name,
      documentId: documentId && `Doc ID: ${documentId}`,
      minAmount: minAmount && `Amount from ${minAmount + (maxAmount ? " to " + maxAmount : "")}`,
      maxAmount: minAmount ? null : maxAmount && `Amount to ${maxAmount}`,
      currency: currency && currencies.find((i) => i?.code === currency)?.name,
      brandId: brandName ? `Brand: ${brandName}` : brandN && `Brand: ${brandN}`,
      pan: pan && `PAN: ${pan}`,
      errorCode: errorCode && `Error: ${errorCode}`,
      searchArchives: searchArchives && "Search Archives",
      includeDeleted: includeDeleted && "Include Cancelled",
      excludeAbandoned: excludeAbandoned && "Exclude Abandoned",
      effectiveAr: effectiveAr && "Effective AR",
      userId: userId && `User ID: ${userId}`,
      requestId: requestId && `Request ID: ${requestId}`,
    };
  });
};
