import Organizations from "pages/organizations";

export const Roles = {
  ADMIN: "ADMIN",
  ADMIN_OPERATOR: "ADMIN_OPERATOR",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const LoginTypes = {
  ADMIN: "ADMIN",
  ORGANIZATION: "ORGANIZATION",
};

export const Permissions = {
  API_KEY: {
    CREATE: "2a84783e-4309-481c-8bc9-dd9755562651",
    LIST: "6957e5b0-641d-437a-bc94-6b08daf8a6f3",
    UPDATE: "u3392980a-67cb-438b-b1fd-67d7ed470d9b",
    DELETE: "f71a6b0d-2ea4-4420-a04d-48a0cbb42152",
  },
  VA: {
    // virtual account
    UPDATE: "691b9a25-1e75-4176-a99f-4f83b3d272ed",
    CREATE: "deceb3a3-7107-465f-81d9-c52909bc1b43",
    GET: "6549b8ef-b52f-43e3-a456-c3d7163faef1",
    UPDATE: "d051ef28-bdf6-4aa1-86c0-b43ae0b1683b",
    DELETE: "a80c0d85-26b0-491e-8387-b2e4beafdcc2",
    CHECK_STATUS: "c49e9edd-de04-4fa7-bc64-521b2bd4d49e",
  },
  ADMIN_USER: {
    INVITE: "e4b675dc-e177-4f76-91a2-579174145170",
    LIST: "75e331d1-5fff-4ad7-98a7-f5609d8427ed",
    DELETE: "79eaad87-d497-46b1-9804-2e2439ff3b65",
  },
  APSPS: {
    LIST: "532f7b9e-77e2-45a1-9c09-8fb948c3107d",
    GET: "69c0ae38-1c2c-4ee0-88df-a9daaaa795e7",
    UPDATE: "a746f787-b022-4c38-878e-85deeabf514d",
  },
  BIN: {
    GET_ROUTES: "ced3f87f-e2f7-42e9-b301-946fcd0c47fe",
    GET_WHITELIST: "b9e00d53-b945-4ec5-aab1-08aa95c8cbb9",
    CREATE_WHITELIST: "a92c9b62-82a5-48ae-b87d-761a47083a42",
    UPLOAD_WHITELIST: "bf718a2e-2e1a-40c4-b338-ef630ccc51a0",
    DELETE_WHITELIST: "303cb211-3416-493f-ae13-8d152e895c1e",
    GET_BLACKLIST: "8105a74e-899c-4754-a643-b7711039bb1e",
    CREATE_BLACKLIST: "2171cebc-0daa-489f-8a77-effe6d63032d",
    DELETE_BLACKLIST: "Delete card blacklist",
    BLACKLIST_BY_TRANSACTION: "78996954-2a10-482b-ba6b-3ea2345c6242",
    UPLOAD_BLACKLIST: "6e69fec3-fcc6-4f7d-acd9-607ef3937ea6",
  },
  PSP: {
    LIST: "276713a2-d136-4a4b-97ba-db6cf85b12ce",
  },
  MID_CREDENTIALS: {
    LIST: "3189b4d5-b4c9-4fa8-95b7-dffd56ca141b",
    UPDATE: "5251b80e-5507-4e17-a054-95fe1b784394",
    DELETE: "d8419e4b-b2d3-4db8-86d4-9e2895743da4",
  },
  CUSTOMER_BLACKLIST: {
    LIST: "1f0e456b-a770-482f-8f04-31b865984484",
    CREATE: "3a962bb3-ade2-4c97-b1cb-a853553c02e8",
    DELETE: "faf78476-7350-478a-9119-fae99b061ee7",
  },
  ERROR_CODE: {
    LIST: "91ef220a-7344-4ff1-86af-0ce25023a2d8",
    LIST_RESOLVED: "c3df006d-bff0-4971-ab3c-cbb88be417d6",
    RESOLVE: "a2b73717-2515-4ec4-8e19-a225f75c6535",
    DELETE: "b6f79868-e5f0-45fc-aa09-1ca4cfdc0b84",
    LIST_UNRESOLVED: "96c95231-addf-48f4-ad67-9e2d9b5f0907",
  },
  BRAND: {
    CREATE: "9f57adf7-45a3-4230-93ea-911ca6c9849b",
    GET: "7815ae51-a84b-4178-ae5d-b302e4fd5adc",
    LIST: "975544a7-49d7-4665-9439-e0bd54509cd0",
    UPDATE: "62de5dc2-16ef-4802-813b-99ab557d2328",
    DELETE: "a071d93f-be64-4fac-8fea-a88bbe4b9c6b",
  },
  MERCHANT: {
    LIST: "cd7c2d17-b551-4f29-81a8-6e6fe1d87c14",
    CREATE: "6f740b92-d9bf-4d92-9d54-702e62e127e5",
    GET: "67639feb-8bde-4c16-accc-59ae78a8e569",
    UPDATE: "a0490abd-80b5-42b1-bbc7-e4cb28265455",
    DELETE: "e87d0366-f98b-412f-8d84-7b47c842f138",
    SETTINGS: "cf25a9d1-125f-4706-9b67-c84989f1e8d4",
  },
  BRAND_MID: {
    LIST: "e275f4f8-4f53-425e-a2c7-c25ad28452c2",
    CREATE: "494aea7b-7329-4ebe-a87d-60dd9936638a",
    GET: "bd111166-6d84-4843-8c1d-85df3395b0ff",
    UPDATE: "58e6a77c-c495-44d9-8861-cb7dbd3c8ce3",
    DELETE: "de74a043-dcb2-4648-84ba-f0e1e3197de4",
  },
  MID_METHOD: {
    LIST: "ec3df44f-127f-498d-941f-f83f488e54e6",
    CREATE: "94b082b5-c64c-47fb-ba1e-2faaf05861e4",
    GET: "1e03c498-a5ed-4277-867a-2f0b2edf2262",
    DELETE: "b0168ad6-68d5-4075-ab52-adeb71d9259c",
  },
  MERCHANT_USER: {
    LIST: "1bca37e8-4183-4cc2-adf0-c3573aeb04ba",
    CREATE: "8a2bb1dd-5a44-4c6c-aaf3-5e84db9c4979",
    GET: "0eaddf44-1722-4ccf-8e0c-ead5372c4fc9",
    DELETE: "1c301587-9aa1-4874-89d4-a7d76a92cab9",
  },
  PSP_MID: {
    LIST: "57fb9d13-be7e-4847-b7b3-b80fe8b5d408",
    CREATE: "9189983c-47c9-4339-824f-b35f6c7e60aa",
    GET: "8fa2a67b-0269-42fc-a4f3-779f6fd99389",
    UPDATE: "b4fb999d-fc48-4b92-831e-e60e7d9bcb2f",
    DELETE: "dcd571cc-85b2-4d89-8a29-cf0757be7924",
    LIST_BRANDS: "e6ce9a0c-6368-42fa-b5be-49aef713290d",
  },
  REFUND: {
    CREATE: "f742cd13-8c49-4013-bc1b-2a88788961fb",
  },
  MERCHANT_REPORT: {
    LIST: "c920db27-dc66-49a0-8603-56ac7e632aac",
    DOWNLOAD: "e79c7acf-5267-4dcc-8b54-bda5e8030c8f",
    GET: "6bb31b1c-376c-489f-b39c-53a9968e693b",
  },
  SETTINGS: {
    GET: "991e3a7a-6230-40e9-8602-2fa1bff0fac1",
    UPDATE: "35f9fb4b-1b3f-49ca-9853-6b1a3f4f2028",
  },
  PAYMENT_OPTION: {
    LIST: "c5378019-5c8d-4721-b585-4b10faf28578",
    GET: "6787a589-53fe-4360-978d-a7194196028f",
    UPDATE: "efe4ebf6-14a4-443d-bc2f-4c92cd908cb8",
  },
  TRANSACTION: {
    LIST: "f074b9dd-30c1-4c8e-a3cd-56d49dd54c96",
    UPDATE: "bca9697a-50ce-4140-8ea5-87197b92f0d9",
    GET: "ab35c50e-81ec-49d6-a70e-ea06feb9a52c",
    DOWNLOAD: "2cd2485e-a5ac-4bee-aa1d-78294de2f70d",
    GET_SUMMARY: "afc79d3d-4282-4cb1-9027-32a05ee96273",
    GET_APPROVAL_RATE: "aaa8ca3f-90b7-4f7b-a039-59340942cbe0",
    GET_EVENT_LOG: "cf37dbab-39f6-4715-9b97-5528b024962a",
    MERCHANT_SUMMARY: "223280b3-d23f-4a90-aba2-55eb9f87c8c9",
    GET_TRANSACTIONS_MERCHANTS_SUMMARY: "914f6e7c-cad8-47d2-9776-ae5dfa104b55",
    GET_TRANSACTIONS_MERCHANTS_SUMMARY_CSV: "28321c2f-4739-43a5-b530-ed90295d5e5a",
    GET_ERRORS_SUMMARY: "86cef42d-3c22-4b00-8e15-6cb3a9bb9e76",
  },
  MISMATCHED_TRANSACTION: {
    LIST: "c306bf1e-3e0c-4027-abd4-eea046344b47",
    GET: "028d0fb0-9c90-45b4-ae14-cd996e950d7b",
  },
  BRAND_WALLET: {
    LIST: "ae04a3a1-dd21-49e9-8d79-0883b4f0fb74",
    TOPUP: "e2e2ec7d-a25b-4206-97a5-24208d9c3572",
    WITHDRAW: "4fab364e-39c7-4d41-b305-1eb355203964",
  },
  ROLE: {
    LIST: "78126bc6-f75e-45a3-948f-3283240460ec",
    CREATE: "a52fed7d-a5ce-46da-87f1-21043b77b1b2",
    UPDATE: "37384402-db75-44b3-b637-f8d51d03eaaf",
    DELETE: "32723acc-89c0-4b33-8cc1-62dca514ad03",
    GET: "78126bc6-f75e-45a3-948f-3283240460ec",
  },
  RECON: {
    EXPORT: "bf7b599f-cf81-4f7f-9ac8-4bcf42cf2773",
    LIST: "e7c9a1de-0130-44cb-948f-5ad23344960c",
    DOWNLOAD: "4e13b8d1-fcc8-40fa-9199-57e7afc7f207",
    PROGRESS: "53f59690-bf8a-499d-a813-4a522c428caa",
  },
  AMOUNT_LIMIT: {
    LIST: "ae2230ed-1ed3-4446-8dc5-3881a8c643d2",
    CREATE: "78c48110-1824-4e79-ae03-3dd388348a18",
    UPDATE: "99d70079-581c-430c-9ed8-736046cf08a3",
    DELETE: "e6b516e7-358c-4547-a7ae-600ae7e9ce4f",
  },
  PSP_METHOD: {
    LIST: "cc82d62b-e988-4c30-8210-22bfe90aee42",
    GET: "842eebac-ba2a-4866-bd08-e42e48f38a2d",
    CREATE: "79534aa2-96d7-42cb-870d-6095a14dd29f",
    UPDATE: "95d1b702-fa24-4997-ac43-fa9e1354c7f9",
  },
  ORGANIZATION: {
    LIST: "f3b4d891-0c74-4fb9-aca0-3997591f515d",
  },
  ORGANIZATION_USER: {
    LIST: "1bca37e8-4183-4cc2-adf0-c3573aeb04ba",
  },
};
export const PagePermissions = {
  MerchantManagement: [Permissions.MERCHANT.LIST],
  Transaction: [Permissions.TRANSACTION.LIST],
  MerchantSummary: [Permissions.TRANSACTION.GET_TRANSACTIONS_MERCHANTS_SUMMARY],
  PspManagement: [Permissions.PSP.LIST, Permissions.MERCHANT.LIST],
  MismatchTransaction: [Permissions.MISMATCHED_TRANSACTION.LIST],
  CardManagement: [
    Permissions.MERCHANT.LIST,
    Permissions.BIN.GET_WHITELIST,
    Permissions.BIN.GET_BLACKLIST,
  ],
  CustomerManagement: [Permissions.MERCHANT.LIST, Permissions.CUSTOMER_BLACKLIST.LIST],
  Reconciliation: [
    Permissions.MERCHANT.LIST,
    Permissions.PSP.LIST,
    Permissions.BRAND.LIST,
    Permissions.RECON.EXPORT,
    Permissions.RECON.LIST,
    Permissions.RECON.DOWNLOAD,
    Permissions.RECON.PROGRESS,
  ],
  ErrorCode: [
    Permissions.MERCHANT.LIST,
    Permissions.ERROR_CODE.LIST_UNRESOLVED,
    Permissions.ERROR_CODE.LIST_RESOLVED,
    Permissions.ERROR_CODE.LIST,
  ],
  Settings: [Permissions.MERCHANT.LIST, Permissions.SETTINGS.GET],
  ErrorSummary: [Permissions.MERCHANT.LIST, Permissions.TRANSACTION.GET_ERRORS_SUMMARY],
  Organizations: [Permissions.ORGANIZATION.LIST],
};
