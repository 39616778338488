import { useEffect } from "react";
import { Stack, Card, Grid } from "@mui/material";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import { useCheckTxStatus, useGetPaymentEvents, useGetTransactionDetail } from "./hooks";
import SoftButton from "components/SoftButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { get } from "../../utils/localStorage";
import { CircularProgress } from "@mui/material";

import TimeLineView from "./components/TimeLineView";
import TreeView from "./components/TreeView";
import { hasPermissions, isSuperAdmin } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";

// This code gets the transaction detail from the API and renders it as a tree view.
const TransactionDetail = () => {
  if (!hasPermissions([Permissions.TRANSACTION.GET, Permissions.TRANSACTION.GET_EVENT_LOG])) {
    return (
      <MainLayout>
        <MainNavbar /> <Error403 />
      </MainLayout>
    );
  }
  const { id } = useParams();
  const { data, mutate: mutateTxDetail, isLoading } = useGetTransactionDetail(id);
  const { data: events } = useGetPaymentEvents({ id });
  const { mutate } = useCheckTxStatus({ id });
  const location = useLocation();
  const transactionReportingPayload = get("transactionReportingPayload") || {};
  const merchantReportingPayload = get("merchantReportingPayload") || {};
  const queryParams = new URLSearchParams(location.search);
  const txR = queryParams.get("txR");
  const original = queryParams.get("original");
  useEffect(() => {
    if (id) mutateTxDetail(id);
  }, []);

  const navigate = useNavigate();

  const createElasticUrl = (id, sessionId) => {
    const urlElastic = `${process.env.REACT_APP_ELASTIC_BASE_URL}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-2M,to:now))&_a=(columns:!(service,requestId,message),filters:!(),grid:(columns:(context.env:(width:164),context.name:(width:190),env:(width:129),level:(width:83),message:(width:2013),requestId:(width:280),service:(width:177),timestamp:(width:230))),index:'${process.env.REACT_APP_ELASTIC_INDEX}',interval:auto,query:(language:kuery,query:'%22${id}%22%20or%20%22${sessionId}%22'),rowHeight:0,rowsPerPage:500,sort:!(!(timestamp,desc)))`;
    window.open(urlElastic);
  };
  const handleGoBack = () => {
    txR
      ? navigate(
          `/${original || "transactions"}?txR=true&page=${transactionReportingPayload?.page}`
        )
      : navigate(`/transaction-management?page=${merchantReportingPayload?.page}`);
  };

  return (
    <MainLayout>
      <MainNavbar />
      <Card>
        <Stack m={3}>
          <Grid container display="flex" gap={3} justifyContent="end">
            <Grid>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={() => createElasticUrl(id, data?.sessionId)}
              >
                Elastic Logs
              </SoftButton>
            </Grid>
            {isSuperAdmin() && (
              <Grid>
                <SoftButton
                  variant="gradient"
                  color="info"
                  onClick={() =>
                    mutate(
                      { id },
                      {
                        onSuccess: () => {
                          mutateTxDetail(id);
                        },
                      }
                    )
                  }
                >
                  Check status
                </SoftButton>
              </Grid>
            )}

            <Grid>
              <SoftButton
                component={Link}
                variant="gradient"
                color="info"
                to={`/transactions/edit/${id}`}
              >
                Edit Transaction
              </SoftButton>
            </Grid>
          </Grid>
        </Stack>

        <SoftBox p={4}>
          {isLoading ? (
            <Grid container width={"100%"} display={"flex"} justifyContent={"center"}>
              <CircularProgress color={"secondary"} />
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={5.5}>
                <TreeView data={data || {}} allData={data} name="Transaction" />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5.5}>
                <TimeLineView events={events} />
              </Grid>
            </Grid>
          )}
        </SoftBox>
        <SoftButton variant="gradient" onClick={handleGoBack}>
          <ArrowBackIosIcon />
          Back
        </SoftButton>
      </Card>
    </MainLayout>
  );
};

export default TransactionDetail;
