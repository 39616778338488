import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { Card, Grid, Switch } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { TextInputField, SelectInputField } from "components/FormField";
import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftBox from "components/SoftBox";
import { useGetTransactionDetail } from "pages/transaction-detail/hooks";
import { useUpdateTransaction } from "pages/transaction-edit/hooks";
import { StatusEnum } from "constants/status";
import FormProvider from "components/FormProviders";
import SoftTypography from "../../components/SoftTypography";
import ButtonSet from "../../components/ButtonSet";

const sanitizeRequest = ({
  transactionId,
  status,
  amount,
  notificationId,
  usdAmount,
  notify,
  bankRefundId,
}) => {
  const filteredPayload = _.pickBy(
    {
      transactionId,
      status,
      amount,
      usdAmount,
      notificationId,
      notify,
    },
    _.identity
  );
  return {
    ...filteredPayload,
    notify: notify !== false,
    amount: amount && typeof amount === "number" ? amount : parseFloat(amount),
    usdAmount: usdAmount && typeof usdAmount === "number" ? usdAmount : parseFloat(usdAmount),
    bankRefundId: bankRefundId == "" ? null : bankRefundId,
  };
};

const TransactionEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, mutate: mutateDetails } = useGetTransactionDetail(id);
  const { mutate, isLoading } = useUpdateTransaction();

  const methods = useForm({
    defaultValues: { transactionId: id },
  });
  const { handleSubmit, reset, control, watch, setValue } = methods;
  const notify = watch("notify");

  const handleSubmitForm = (values) => {
    mutate(sanitizeRequest({ ...values, id }), {
      onSuccess: () => {
        handleGoTransaction();
      },
    });
  };
  const handleGoTransaction = () => {
    navigate(`/transaction-management`);
  };
  useEffect(() => {
    mutateDetails();
  }, []);
  useEffect(() => {
    reset({
      transactionId: id,
      status: data?.status && data.status,
      amount: data?.amount && data.amount,
      usdAmount: data?.fx?.amount && data?.fx?.amount,
      notificationId: data?.notificationId && data.notificationId,
      notify: true,
      bankRefundId: data?.bankRefundId && data.bankRefundId,
    });
  }, [data]);

  const options = Object.keys(StatusEnum).map((key) => ({ value: key, label: key }));
  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "transactions", route: "transactions" },
          { label: _.get(data, "merchant", id), route: id },
        ]}
      />
      <Grid container justifyContent="center" sx={{ marginTop: 10 }}>
        <Grid item xs={12} lg={8}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextInputField label="Transaction ID" name="transactionId" disabled />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectInputField
                      name="status"
                      label="Status"
                      message="Required"
                      options={options}
                      placeholder="Select an option"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInputField label="Bank Refund ID" name="bankRefundId" />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInputField label="Amount" name="amount" />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInputField label="USD Amount" name="usdAmount" />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInputField label="Notification ID" name="notificationId" />
                  </Grid>
                  <SoftBox display="flex" mb={0.25} ml={3}>
                    <SoftBox width="80%" mr={2} ml={0.5}>
                      <SoftTypography variant="button" fontWeight="bold">
                        Notify
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mt={0.25}>
                      <Switch
                        checked={notify}
                        onChange={(_event, checked) => setValue("notify", checked)}
                      />
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid mt={3}>
                  <ButtonSet onClose={handleGoTransaction} isLoading={isLoading} title={"Update"} />
                </Grid>
              </FormProvider>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default TransactionEdit;
