import React, { useEffect, useState } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";

import SoftBox from "components/SoftBox";
import _ from "lodash";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import PatchExportModal from "./PatchExportModal";
import { useGetJobInfo } from "../hooks";

const JobStatus = ({ job, merchants, status }) => {
  const [title, setTitle] = useState("");
  const { mutate: getJobInfo } = useGetJobInfo();

  useEffect(() => {
    if (status !== "processing" && status !== "nondownloadable" && status !== "error") {
      getJobInfo(
        { jobId: job.job_id },
        {
          onSuccess: (data) => {
            const merchant = _.find(merchants, { _id: data?.merchant_id });
            setTitle(`${data.export_id} - ${merchant?.name} - ${data.status}`);
          },
        }
      );
    }
  }, [job, status]);
  if (status == "processing" || status == "nondownloadable" || status == "error") {
    const color = status == "nondownloadable" || status == "error" ? "error" : "text";
    const title = `${job?.job_id || "N/A"} - ${job?.message || ""}`;
    return (
      <SoftTypography variant="body2" color={color}>
        {title}
      </SoftTypography>
    );
  } else {
    return (
      <SoftTypography variant="body2" color="text">
        {title}
      </SoftTypography>
    );
  }
};
JobStatus.propTypes = {
  job: PropTypes.object,
  merchants: PropTypes.array,
  status: PropTypes.string,
};

const ExportMultiSelect = ({ onChange, sx, jobs, jobStatuses, checks, merchants }) => {
  const { borderWidth, borderRadius } = borders;
  const { inputColors } = colors;

  const jobStatus = (jobId) => {
    const progress = _.get(jobStatuses, `${jobId}.progress`, 0);
    const message = _.get(jobStatuses, `${jobId}.message`, "");
    if (progress >= 0 && progress < 1) {
      return "processing";
    }
    if (progress == 1 && message == "No matching transactions found") {
      return "nondownloadable";
    }

    if (progress == 1 && message == "Export completed") {
      return "downloadable";
    }
    if (progress == 1) {
      return "processing";
    }
    if (progress == -1) {
      return "error";
    }
  };

  const errorJob = (jobId) => {
    if (jobStatus(jobId) == "nondownloadable" || jobStatus(jobId) == "error") {
      return true;
    }
    return false;
  };

  return (
    <SoftBox
      sx={{
        border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
        borderRadius: `${borderRadius.md} !important`,
        height: "12rem",
        p: 2,
        ...sx,
      }}
    >
      {_.map(jobs, (job) => {
        return (
          <SoftBox display="flex" alignItems="center" px={1} key={job}>
            {jobStatus(job) == "downloadable" && (
              <FormControlLabel
                value={job}
                onChange={onChange}
                control={<Checkbox sx={{ ml: 2 }} checked={_.get(checks, job, false)} />}
              />
            )}
            {jobStatus(job) == "processing" && (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  height: "40px",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={Math.round(_.get(jobStatuses, `${job}.progress`, 0) * 100)}
                  sx={{ height: "40px" }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">
                    {Math.round(_.get(jobStatuses, `${job}.progress`, 0) * 100)}%
                  </Typography>
                </Box>
              </Box>
            )}
            {errorJob(job) && (
              <Box
                sx={{
                  width: "47px",
                }}
              ></Box>
            )}

            <SoftBox display="flex" flexDirection="column" justifyContent="center">
              <SoftTypography
                component="div"
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                color={errorJob(job) ? "error" : "text"}
                display="flex"
              >
                <JobStatus
                  job={_.get(jobStatuses, job, {})}
                  merchants={merchants}
                  status={jobStatus(job)}
                />
                {jobStatus(job) == "downloadable" && <PatchExportModal jobId={job} />}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      })}
    </SoftBox>
  );
};

export default ExportMultiSelect;

// Typechecking props of the ReconciliationFilters
ExportMultiSelect.propTypes = {
  onChange: PropTypes.func,
  sx: PropTypes.any,
  jobs: PropTypes.array,
  jobStatuses: PropTypes.object,
  checks: PropTypes.object,
  merchants: PropTypes.array,
};
