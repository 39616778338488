import React, { useEffect, useState } from "react";

import _ from "lodash";
import FormProvider from "components/FormProviders";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, useFieldArray } from "react-hook-form";
import { useGetMerchants } from "../merchant-management/hooks";
import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import ExportMultiSelect from "./components/ExportMultiSelect";
import { useGetPsps } from "pages/psp-management/hooks";
import FxRate from "./components/FxRate";
import { sanitizeRequest, schema, defaultValues } from "./schemas";
import { toastError } from "utils/toast";
import { useExecuteJob, useGetJobStatus, V2_EXPORT_DOWNLOAD } from "./hooks";
import { saveDataToFile } from "utils/file";
import { getToken } from "utils/cache";
import { getTemplatePath } from "utils/string";
import ExportSelectForm from "./components/ExportSelectForm";

const ReconciliationExport = () => {
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const exportError = errors.exports;

  const {
    fields: exports,
    remove: removeExport,
    append: appendExport,
  } = useFieldArray({
    control,
    name: "exports",
  });
  const { data: merchants } = useGetMerchants();
  const { data: psps } = useGetPsps();

  // hooks
  const { data: jobs, mutate: executeJob } = useExecuteJob();

  const [jobStatuses, setJobStatuses] = useState({});
  const [checks, setChecks] = useState({});
  const [allowSave, setAllowSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { mutateAsync: getJobStatus } = useGetJobStatus();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    setIsLoading(true);
    const payloads = sanitizeRequest(values, psps);
    if (payloads.length == 0) {
      toastError({ description: "Please select merchants" });
    }
    executeJob(payloads, {
      onSuccess: (data) => {
        let doneJobs = [];
        const interval = setInterval(async () => {
          if (data?.jobs?.length == doneJobs?.length) {
            clearInterval(interval);
          }
          doneJobs = await getAllJobStatuses(data);
        }, 3000);
      },
    });
  };

  const getAllJobStatuses = async (resp) => {
    let doneJobs = [];
    if (resp.jobs) {
      for (let i = 0; i < resp.jobs.length; i++) {
        const data = await getJobStatus({ jobId: resp.jobs[i] });
        const jobStatus = {};

        if (!doneJobs.includes(resp.jobs[i])) {
          if (_.get(data, "progress") == -1) {
            doneJobs.push(resp.jobs[i]);
          }
          if (
            _.get(data, "progress") == 1.0 &&
            _.get(data, "message") !== "Processing transactions"
          ) {
            doneJobs.push(resp.jobs[i]);
          }
        }
        jobStatus[resp.jobs[i]] = data;
        setJobStatuses((prev) => ({ ...prev, ...jobStatus }));
      }
    }
    return doneJobs;
  };

  const onJobCheckChange = (e) => {
    const check = {};
    check[e.target.value] = e.target.checked;
    setChecks((prev) => ({ ...prev, ...check }));
  };
  const onSaveReports = async () => {
    _.forEach(Object.keys(checks), async (k) => {
      if (checks[k] == true) {
        setIsDownloading(true);
        const downloadUrl = `${process.env.REACT_APP_BASE_URL}${getTemplatePath(
          V2_EXPORT_DOWNLOAD,
          { jobId: k }
        )}`;
        const response = await fetch(downloadUrl, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        const blob = await response.blob();
        saveDataToFile(blob, `${k}.zip`, "application/zip");
        setIsDownloading(false);
      }
    });
  };

  useEffect(() => {
    const _allowSave = _.some(Object.keys(checks), (k) => checks[k] == true);
    setAllowSave(_allowSave);
  }, [checks]);
  useEffect(() => {
    const isProcessing = _.some(
      jobStatuses,
      (j) =>
        (_.get(j, "progress") > 0 && _.get(j, "progress") < 1) ||
        (_.get(j, "progress") == 1 && _.get(j, "message") === "Processing Transactions")
    );
    setIsLoading(isProcessing);
  }, [jobStatuses]);

  return (
    <MainLayout>
      <MainNavbar />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {exportError && !_.isArray(exportError) && (
                  <SoftBox mt={0.75}>
                    <SoftTypography component="div" variant="caption" color="error">
                      {_.get(exportError, "message", "Unknown error")}
                    </SoftTypography>
                  </SoftBox>
                )}

                <div>
                  {_.map(exports, (field, index) => {
                    return (
                      <ExportSelectForm
                        key={field.id}
                        merchants={merchants}
                        nestedIndex={index}
                        psps={psps}
                        remove={removeExport}
                      />
                    );
                  })}
                  <Grid container spacing={2}>
                    <Grid xs={2}>
                      <SoftBox display="flex" justifyContent="space-between" mt={2} ml={2}>
                        <SoftButton sx={{ mt: 2, mb: 2 }} onClick={() => appendExport({})}>
                          + Add Export
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                    <Grid xs={8.5}></Grid>
                  </Grid>
                </div>

                <ExportMultiSelect
                  jobs={_.get(jobs, "jobs", [])}
                  jobStatuses={jobStatuses}
                  onChange={onJobCheckChange}
                  checks={checks}
                  merchants={merchants}
                />
                <Grid container display="flex" justifyContent="space-between">
                  <SoftBox>
                    <FxRate merchants={merchants} />
                  </SoftBox>
                  <SoftBox sx={{ mt: 2 }}>
                    <SoftButton
                      variant="outlined"
                      color="success"
                      sx={{ mr: 2 }}
                      type="submit"
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      Run
                    </SoftButton>
                    <SoftButton
                      disabled={!allowSave}
                      variant="outlined"
                      color="text"
                      type="button"
                      onClick={onSaveReports}
                      isLoading={isDownloading}
                    >
                      Save Reports
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </FormProvider>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ReconciliationExport;
