import React from "react";

import { Dialog, DialogContent, DialogTitle, Stack, Grid, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDisclosure } from "hooks/useDisclosure";
import JsonView from "pages/transaction-detail/components/JsonView";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

const ViewExportDetails = ({ details }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconButton onClick={onOpen}>
        <VisibilityIcon />
      </IconButton>
      <Dialog open={isOpen} onClose={onClose} sx={{ overflow: "visible" }} fullWidth maxWidth="sm">
        <DialogTitle>Patch export</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <SoftBox p={2}>
            <Grid container spacing={2}>
              <Grid xs={12} mt={2} ml={2}>
                <JsonView data={details} />
              </Grid>
            </Grid>

            <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
              <SoftButton onClick={onClose}>Close</SoftButton>
            </Stack>
          </SoftBox>
        </DialogContent>
      </Dialog>
    </>
  );
};
ViewExportDetails.propTypes = {
  details: PropTypes.object,
};

export default ViewExportDetails;
