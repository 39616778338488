import * as Yup from "yup";
import { generateDateRange } from "utils/time";

export const defaultValues = {
  merchants: null,
  fromCurrency: "",
  toCurrency: "",
  fromDate: null,
  toDate: null,
  rate: null,
  timeFrame: null,
};

export const schema = Yup.object().shape({
  merchants: Yup.array().required("merchants is required"),
  rate: Yup.number().required("Fx Rate is required"),
  fromCurrency: Yup.string().required("From Currency is required"),
  toCurrency: Yup.string().required("To Currency is required"),
  fromDate: Yup.date()
    .test("fromDate-is-null", "Start Date is required", (stDate, context) => {
      if (stDate) {
        return true;
      }
      if (_.isEmpty(stDate) && _.isEmpty(context.parent.timeFrame)) {
        return false;
      }
      return true;
    })
    .nullable(),
  toDate: Yup.date()
    .test("fromDate-is-null", "End Date is required", (eDate, context) => {
      if (eDate) {
        return true;
      }
      if (_.isEmpty(eDate) && _.isEmpty(context.parent.timeFrame)) {
        return false;
      }
      return true;
    })
    .nullable(),
});

export const sanitizeRequest = ({
  merchants,
  rate,
  fromCurrency,
  toCurrency,
  fromDate,
  toDate,
  timeFrame,
}) => {
  let payload = [];
  let _fromDate = fromDate;
  let _toDate = toDate;

  if (timeFrame) {
    const dateRange = generateDateRange(timeFrame);
    _fromDate = dateRange.fromDate;
    _toDate = dateRange.toDate;
  }
  _fromDate = _fromDate.split(" ")[0];
  _toDate = _toDate.split(" ")[0];

  _.map(merchants, (merchant) => {
    payload.push({
      merchant_id: merchant,
      from: fromCurrency,
      to: toCurrency,
      rate,
      start_date: _fromDate,
      end_date: _toDate,
    });
  });
  return payload;
};
