/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { countriesFlags } from "../../../../constants/countriesFlags";
import { getSymbolFromCurrency } from "../../../../utils/string";
import { itemArrow } from "../../../Sidenav/styles/sidenavItem";

function ComplexReportsDoughnutChartItem({ image, title, percentage, hasBorder, allData }) {
  const { borderWidth } = borders;
  const [open, setOpen] = useState(false);
  const { light } = colors;
  const code = countriesFlags.find((country) => country.name === title)?.code; //current country code
  const total = code && allData && _.sum(Object.values(allData[code])); //the total amount of all transactions of the current country
  const percentageCurrency = (item) => {
    if (total) {
      return ((item / total) * 100).toFixed(2);
    }
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderBottom: hasBorder ? `${borderWidth[1]} solid ${light.main}` : "none" }}
    >
      <Grid item xs={7}>
        <SoftBox display="flex" mt={1} px={2}>
          {image && (
            <SoftBox mr={1}>
              <SoftAvatar src={image} size="sm" alt="title" />
            </SoftBox>
          )}
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {title}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      {allData && (
        <Grid item xs={1} onClick={() => setOpen(!open)}>
          <Icon component="i" sx={(theme) => itemArrow(theme, { open })}>
            expand_less
          </Icon>
        </Grid>
      )}
      <Grid item xs={5}>
        <SoftBox px={1} textAlign="center">
          <SoftTypography variant="caption" color="text" fontWeight="medium">
            {percentage}
          </SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <Grid container display="flex" flexDirection="column" px={1}>
          {allData &&
            Object.keys(allData)?.map((countryCode) => {
              const currencies = allData[countryCode];
              if (code === countryCode)
                return Object.keys(currencies).map((currency, index) => (
                  <Collapse
                    key={currency + index}
                    in={open}
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    sx={{ fontSize: "14px" }}
                  >
                    {currency +
                      ": " +
                      currencies[currency] +
                      getSymbolFromCurrency(currency) +
                      " - " +
                      percentageCurrency(currencies[currency]) +
                      "%"}
                  </Collapse>
                ));
            })}
        </Grid>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of ComplexReportsDoughnutChartItem
ComplexReportsDoughnutChartItem.defaultProps = {
  image: "",
  hasBorder: false,
};

// Typechecking props for the ComplexReportsDoughnutChartItem
ComplexReportsDoughnutChartItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  hasBorder: PropTypes.bool,
  allData: PropTypes.object,
};

export default ComplexReportsDoughnutChartItem;
