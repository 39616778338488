import _ from "lodash";

export const StatusEnum = {
  INIT: "INIT",
  PENDING: "PENDING",
  USER_CANCEL: "USER_CANCEL",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
  DECLINED: "DECLINED",
  EXPIRED: "EXPIRED",
  REFUNDED: "REFUNDED",
  CAPTURED: "CAPTURED",
  VOIDED: "VOIDED",
  USER_ABANDONED: "USER_ABANDONED",
  CHARGEBACK: "CHARGEBACK",
};

export const statusOptions = [{ value: "", label: "All" }].concat(
  _.map(StatusEnum, (item) => ({ value: item, label: item }))
);

export const TypeEnum = {
  APM: "APM",
  BANK: "BANK",
  CARD: "CARD",
  CASH: "CASH",
  CRYPTO: "CRYPTO",
  P2P: "P2P",
  TOPUP: "TOPUP",
  WALLET: "WALLET",
  WITHDRAW: "WITHDRAW",
};
export const typeOptions = [{ value: "", label: "All" }].concat(
  _.map(TypeEnum, (item) => ({ value: item, label: item }))
);

export const CategoryEnum = {
  PAYIN: "PAYIN",
  PAYOUT: "PAYOUT",
};
export const categoryOptions = [{ value: "", label: "All" }].concat(
  _.map(CategoryEnum, (item) => ({ value: item, label: item }))
);
export const categoryOptionsSlim = _.map(CategoryEnum, (item) => ({ value: item, label: item }));

export const StatusColorEnum = {
  INIT: "default",
  PENDING: "secondary",
  USER_CANCEL: "warning",
  PROCESSING: "warning",
  COMPLETED: "success",
  ERROR: "error",
  DECLINED: "error",
  EXPIRED: "error",
  REFUNDED: "warning",
  CAPTURED: "warning",
  VOIDED: "warning",
  USER_ABANDONED: "warning",
};

export const ActiveStatusColorEnum = {
  true: "success",
  false: "error",
};

export const ActiveStatusEnum = {
  true: "Active",
  false: "Inactive",
};

export const EnabledColorEnum = {
  true: "success",
  false: "error",
};

export const statusIcon = {
  PENDING: "pending",
  SUCCESS: "check_circle",
  FAILED: "cancel",
  ERROR: "error",
  DECLINED: "cancel",
  INIT: "not_started",
  VOIDED: "warning",
  CAPTURED: "warning",
  REFUNDED: "keyboard_return",
  EXPIRED: "error",
  PROCESSING: "refresh",
  USER_CANCEL: "warning",
  COMPLETED: "check_circle",
  USER_ABANDONED: "access_time",
};
