import React from "react";
import _ from "lodash";
import DataTable from "../../../../examples/Tables/DataTable";
import { useParams } from "react-router-dom";

import { useGetOrganizationUsers, useDeleteOrganizationUser } from "./hooks";
import AddOrganizationUserModal from "./components/AddOrganizationUserModal";
import EnabledChip from "../../../../components/EnabledChip";
import ResetOrganizationUser from "./components/ResetOrganizationUser";
import EditUserModal from "./components/EditUserModal";
import Roles from "components/Roles";
import { hasPermission } from "utils/roles";
import { Permissions } from "constants/roles";
import Error403 from "components/Error403";
import DeleteModal from "components/Modal/DeleteModal";
import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";

const OrganizationUsers = () => {
  const { id: organizationId } = useParams();
  if (!hasPermission(Permissions.ORGANIZATION_USER.LIST)) {
    return <Error403 />;
  }
  // hooks
  const { data } = useGetOrganizationUsers(organizationId);
  const { isLoading: isDeleting, mutateAsync: deleteUser } = useDeleteOrganizationUser();

  const getRows = () => {
    return _.map(data, (item) => ({
      id: item.id,
      name: item.name,
      surname: item.surname,
      email: item.email,
      promptPassChange: <EnabledChip label={item.promptPassChange} size="small" />,
      roles: <Roles roles={item.roles} />,
      merchantId: item.merchantId,
      actions: (
        <>
          <DeleteModal
            type="icon"
            title="Delete Organization User"
            message={
              <>
                Are you sure you want to delete organization user <b>{_.get(item, "name", "")}</b>?
                You will not be able to recover this organization user?
              </>
            }
            onDelete={async () => {
              await deleteUser({ id: organizationId, userId: item.id });
            }}
            isLoading={isDeleting}
          />
          <ResetOrganizationUser user={item} />
          <EditUserModal
            email={item.email}
            name={item.name}
            userId={item.id}
            currentRoles={item.roles}
          />
        </>
      ),
    }));
  };
  return (
    <Card sx={{ mt: 2 }}>
      <SoftBox p={3}>
        <AddOrganizationUserModal />
        <DataTable
          table={{
            columns: [
              { Header: "Name", accessor: "name" },
              { Header: "Surname", accessor: "surname" },
              { Header: "Email Address", accessor: "email" },
              { Header: "Prompt Password Change", accessor: "promptPassChange" },
              { Header: "Roles", accessor: "roles" },
              { Header: "actions", accessor: "actions" },
            ],
            rows: getRows() || [],
          }}
        />
      </SoftBox>
    </Card>
  );
};

export default OrganizationUsers;
