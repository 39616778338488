import { Dialog, DialogContent, DialogTitle, Stack, Grid, Divider } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";

import { TextInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useDisclosure } from "hooks/useDisclosure";
import FormProvider from "components/FormProviders";
import PaymentMethodForm from "pages/brand-detail/components/PspMids/components/PaymentMethodForm";
import { defaultValues, sanitizeRequest } from "pages/brand-detail/schemas/paymentMethod";
import {
  sanitizeRequest as sanitizeTemplate,
  schema,
} from "pages/brand-detail/schemas/paymentMethodsTemplate";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateTemplate } from "pages/brand-detail/hooks";

const AddNewTemplate = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  //form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control, reset } = methods;

  //hooks
  const { mutate, isLoading } = useCreateTemplate();

  const handleSubmitForm = (values) => {
    const payload = sanitizeRequest(values);
    const templatePayload = sanitizeTemplate({ ...payload, name: values.name });
    mutate(templatePayload, {
      onSuccess: async () => {
        handleClose();
      },
    });
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <>
      <SoftButton variant="gradient" color="info" onClick={onOpen}>
        Add Template
      </SoftButton>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Add New Template</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <SoftBox py={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextInputField name="name" label="Template Name" />
                </Grid>
              </Grid>
              <Divider />
              <PaymentMethodForm showPaymentOption={false} />
              <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                <SoftButton onClick={handleClose}>Cancel</SoftButton>
                <SoftButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Add Template
                </SoftButton>
              </Stack>
            </SoftBox>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewTemplate;
