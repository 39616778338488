import _ from "lodash";
import { useMutation } from "react-query";
import request from "utils/request";
import { getTemplatePath } from "utils/string";
import { GET_BRANDS } from "pages/brand-detail/hooks";

const V2_EXPORTS = "/reconciliation/v2/export";
const V2_EXPORT = "/reconciliation/v2/export/:jobId";
export const V2_EXPORT_DOWNLOAD = `${V2_EXPORT}/download`;
const V2_EXPORT_HISTORY = `${V2_EXPORTS}/get_with_post`;

const getBrand = ({ merchantId }) => {
  const URL = getTemplatePath(GET_BRANDS, { merchantId });
  return request.get(URL);
};
export const useGetBrands = () => {
  return useMutation(getBrand);
};

// get exports
const getExports = (payload) => {
  return request.post(V2_EXPORT_HISTORY, payload);
};
export const useGetExports = () => {
  return useMutation(getExports);
};
