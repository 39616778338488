import _ from "lodash";
import { useMutation } from "react-query";
import request from "utils/request";
import { toastSuccess } from "utils/toast";
import { getTemplatePath } from "utils/string";
import { GET_BRANDS } from "pages/brand-detail/hooks";

const MERCHANT_HISTORICAL_RATE_DATE_RANGE = "/reconciliation/convert/merchant/:merchant_id/range";
const V2_EXPORTS = "/reconciliation/v2/export";
const V2_EXPORT = "/reconciliation/v2/export/:jobId";
const V2_EXPORT_PROGRESS = `${V2_EXPORT}/progress`;
export const V2_EXPORT_DOWNLOAD = `${V2_EXPORT}/download`;

const getBrand = ({ merchantId }) => {
  const URL = getTemplatePath(GET_BRANDS, { merchantId });
  return request.get(URL);
};
export const useGetBrands = () => {
  return useMutation(getBrand);
};

// fx rate
const setHistoricalRate = (data) => {
  const { merchant_id, ...payload } = data;
  const url = getTemplatePath(MERCHANT_HISTORICAL_RATE_DATE_RANGE, { merchant_id });
  return request.post(url, payload);
};

export const useSetHistoricalRate = () => {
  return useMutation(setHistoricalRate, {
    onSuccess: () => {
      toastSuccess({ description: "Set Merchants historical rate successfully" });
    },
  });
};

// execute background job
const executeJob = (payload) => {
  return request.post(V2_EXPORTS, payload);
};
export const useExecuteJob = () => {
  return useMutation(executeJob);
};

// get job status
const jobStatus = ({ jobId }) => {
  const URL = getTemplatePath(V2_EXPORT_PROGRESS, { jobId });
  return request.get(URL);
};
export const useGetJobStatus = () => {
  return useMutation(jobStatus);
};

// patch an export
const patchExport = ({ jobId, ...payload }) => {
  const URL = getTemplatePath(V2_EXPORT, { jobId });
  return request.patch(URL, payload);
};

export const usePatchExport = () => {
  return useMutation(patchExport);
};

// get job info
const getJobInfo = ({ jobId }) => {
  const URL = getTemplatePath(V2_EXPORT, { jobId });
  return request.get(URL);
};

export const useGetJobInfo = () => {
  return useMutation(getJobInfo, { retry: 5 });
};
