import { Card, Grid } from "@mui/material";
import { SelectInputField, TextInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { useGetMerchants } from "../../merchant-management/hooks";

const CardsFilter = (props) => {
  const { onSubmit, tab } = props;
  const { setValue, getValues } = useFormContext();
  const { data } = useGetMerchants();
  const options = [{ value: "", label: "All" }].concat(
    _.map(data, (item) => ({ value: item._id, label: item.name }))
  );
  const handleClickCleanFilters = async () => {
    const { limit, page, ...currentFilters } = await getValues(); // Get the current filter values
    await Object.keys(currentFilters).forEach((key) => {
      setValue(key, "");
    }); // Reset the values of all current filters
    await onSubmit();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextInputField label="Card Number" name="cardnumber" />
              </Grid>
              {tab === 0 && (
                <Grid item xs={12}>
                  <SelectInputField
                    label="Merchants"
                    name="merchantId"
                    message="Required"
                    options={options}
                    placeholder="Select an option"
                  />
                </Grid>
              )}
            </Grid>
            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={handleClickCleanFilters}
              >
                Clean filter
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardsFilter;

// Typechecking props of the ReconciliationFilters
CardsFilter.propTypes = {
  onSubmit: PropTypes.func,
  tab: PropTypes.number,
};
