import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import FormProvider from "components/FormProviders";

import MainLayout from "examples/LayoutContainers/MainLayout";
import MainNavbar from "examples/Navbars/MainNavbar";
import SoftButton from "components/SoftButton";
import DataTable from "examples/Tables/DataTable";
import StatusChip from "components/StatusChip";
import ViewExportDetails from "./components/ViewExportDetails";

import { useForm } from "react-hook-form";
import { useGetMerchants } from "../merchant-management/hooks";
import { Card, Grid } from "@mui/material";
import { MultipleSelectField, SelectInputField } from "components/FormField";
import SoftBox from "components/SoftBox";
import { typeOptions, reconFilterStatusOption } from "constants/reconciliation";
import { useGetExports, useGetBrands } from "./hooks";
import { generateOptions } from "utils/options";
import { sanitizeHistory, defaultValues, schema } from "./schemas/search";
import { yupResolver } from "@hookform/resolvers/yup";
import { fDateTimeSuffix } from "utils/formatTime";

const Reconciliation = () => {
  const [brandOptions, setBrandOptions] = useState([]);
  const navigate = useNavigate();

  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, watch } = methods;
  const merchantWatch = watch("merchant");

  const { mutate } = useGetBrands();
  const { data: merchants } = useGetMerchants();

  // hooks
  const { mutate: getHistory, data: exports } = useGetExports();

  const merchantOptions = generateOptions(merchants, false);

  // create a function that handles the form submission
  const onSubmit = (values) => {
    getHistory(sanitizeHistory(values));
  };

  const getRows = () => {
    return _.map(_.get(exports, "exports", []), (item) => {
      return {
        id: item.export_id,
        start_date: fDateTimeSuffix(item.start_date),
        end_date: fDateTimeSuffix(item.end_date),
        status: <StatusChip label={item.status} size="small" tooltip={item.status} />,
        action: <ViewExportDetails details={item.summary} />,
      };
    });
  };

  const handleNewExport = () => {
    navigate("/reconciliation-exports");
  };

  useEffect(() => {
    if (merchantWatch) {
      mutate(
        { merchantId: merchantWatch },
        {
          onSuccess: (data) => {
            const _brandOptions = generateOptions(data, false);
            setBrandOptions(_brandOptions);
          },
        }
      );
    }
  }, [merchantWatch]);

  return (
    <MainLayout>
      <MainNavbar />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SelectInputField options={merchantOptions} label="Merchant" name="merchant" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelectField options={brandOptions} name="brand_ids" label="brands" />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelectField options={typeOptions} name="type" label="Type" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelectField
                      options={reconFilterStatusOption}
                      name="status"
                      label="filter statuses"
                    />
                  </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="space-between">
                  <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                    Search
                  </SoftButton>
                  <SoftButton
                    variant="gradient"
                    color="secondary"
                    sx={{ mt: 2 }}
                    onClick={handleNewExport}
                  >
                    New Export
                  </SoftButton>
                </Grid>
              </FormProvider>
            </SoftBox>
          </Card>
        </Grid>
        <DataTable
          table={{
            columns: [
              { Header: "id", accessor: "id" },
              { Header: "start date", accessor: "start_date" },
              { Header: "end date", accessor: "end_date" },
              { Header: "status", accessor: "status" },
              { Header: "action", accessor: "action" },
            ],
            rows: getRows(),
          }}
        />
      </Grid>
    </MainLayout>
  );
};

export default Reconciliation;
