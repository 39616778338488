import { useMutation, useQuery, useQueryClient } from "react-query";
import request from "utils/request";
import { toastSuccess } from "../../utils/toast";

const GET_SETTINGS = "/settings";
const UPDATE_SETTINGS = "/settings";

const getSettings = () => request.get(GET_SETTINGS);
const updateSettings = (data) => request.put(UPDATE_SETTINGS, data);
export const useGetSettings = () => {
  return useQuery([GET_SETTINGS], getSettings);
};

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSettings, {
    onSuccess: () => {
      toastSuccess({ description: "Update settings has been success" });
      queryClient.invalidateQueries([GET_SETTINGS]);
    },
  });
};
