import { useState } from "react";
import { List } from "@mui/material";

import { TreeNode } from "pages/mismatched-transaction-detail";

// This component renders a tree view of the given data. The data is an object that contains a list of transactions. Each transaction has a list of children transactions. The list of children transactions is only displayed if the transaction is expanded. The transaction is expanded if its id is in the openNodes array. The openNodes array is managed by the TreeView component.
// eslint-disable-next-line react/prop-types
const TreeView = ({ data, allData, name }) => {
  const [openNodes, setOpenNodes] = useState(["Transaction"]);

  return (
    <List component="nav">
      <TreeNode
        name={name}
        value={data}
        openNodes={openNodes}
        setOpenNodes={setOpenNodes}
        allData={allData}
      />
    </List>
  );
};

export default TreeView;
